import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import ContentScreen from "./components/ContentScreen";
import LogIn from "./components/Login";
import ESM from "./components/ESM"
import {MyLoadingSpinner} from "./components/Utils";
import {getCookieConsentValue} from "react-cookie-consent";
import {fetchDataFromUrl} from "./components/API";

export const LocalisaContext = React.createContext(null)
export const LoadingContext = React.createContext(null)
export const ESMContext = React.createContext((e)=>{console.log("uninitialised" + e)})

export default function App(){
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [idUser, setIdUser] = useState(localStorage.getItem("userId"));
  const [isLoaded, setIsLoaded] = useState(0);
  const [isLoading, setIsLoading] = useState(0);
  const setError = useRef(null)

  useEffect(() => {
    if(localStorage.getItem("language") === null){
      localStorage.setItem("language", "DE");
      localStorage.setItem("helpers", "1");
    }

    if(token){
      checkToken(token);
    }
    else{
      setIsLoaded(1);
    }
  },[token]);

  function handleVerify(response){
    if(getCookieConsentValue("acceptCookies") === "true"){
      localStorage.setItem("userId", response["id"]);
    }
    setIdUser(response["id"]);
  }

  function checkToken(token){
    fetchDataFromUrl(token, "verifyToken", handleVerify, setError?.current)
    setIsLoaded(1);
  }

  return (
    <div>
      <LoadingContext.Provider value={setIsLoading}>
        <ESMContext.Provider value={setError?.current}>
          <MyLoadingSpinner isLoading={isLoading}/>
          <ESM setErrorRef={setError}/>
          {token && idUser && isLoaded ?
            <LocalisaContext.Provider value={token}>
              <ContentScreen idUser={parseInt(idUser)}/>
            </LocalisaContext.Provider>:
            isLoaded?
            <LogIn setToken={setToken}/>:
            null}
        </ESMContext.Provider>
      </LoadingContext.Provider>
    </div>
  );
}
