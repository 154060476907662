import * as React from 'react';
import {useContext, useEffect, useState} from 'react';

import './components.css';
import {
  accept_friend_1,
  accept_friend_2, accept_t, add_as_friend, adD_t, add_t, cancel_t, decline_t,
  enter_existing_name_email,
  name_or_email, new_people_to_connect, requests_t,
  search_new_friends,
  search_t,
  t
} from "./languages";
import {showModal} from "./Utils";
import helperIcon from '../assets/helperIcon.png';
import {BackendUrl, fetchDataFromUrl, fetchLocalisa} from "./API";
import {ESMContext, LoadingContext, LocalisaContext} from "../App";
import {ContentContext} from "./ContentScreen";

export default function AddPerson({submit, buffer, friends, friendRequests}){
  const [isError, setIsError] = useState(false);
  const [searchValue, setSearchValue] = useState(buffer);
  const [foundPersons, setFoundPersons] = useState([]);
  const [friendRequestsTmp, setFriendRequests] = useState(friendRequests);
  const [selectedPerson, setSelectedPerson] = useState(-1);
  const [selectedPersonReq, setSelectedPersonReq] = useState(-1);
  const [addFriendModel, setAddFriendModel] = useState(0);
  const [hiddenPersons, setHiddenPersons] = useState([]); //todo kunt ma besser mochen mit neuen fetch? oder so lassen
  const helpers = localStorage.getItem("helpers");
  const token = useContext(LocalisaContext);
  const setContent = useContext(ContentContext)
  const setLoading = useContext(LoadingContext)
  const setError = useContext(ESMContext)

  function addFriend(id){
    const url = `friends?friendID=${id}`;
    //Todo do not go back to persons when close modal but update data fetch!
    fetchLocalisa(token, url, ()=>setContent(101),() => setIsError(true), setLoading);

  }

  function updateSearch(){
    //todo, exclude already requested persons
    if(searchValue.length){
      const url = "users?q=" + searchValue.trim();

      fetchDataFromUrl(token, url, (e)=>setFoundPersons(e.list), setError, setLoading);

    }
  }

  useEffect(() => {
    updateSearch();
  }, []);
  useEffect(() => {
    submit.current = wrapperAddFriend;
  }, [selectedPerson]);

  function wrapperAddFriend(){
    addFriend(foundPersons[selectedPerson].id);
  }

  function confirmFriend(setIsError, setLoading, id){
    addFriend(id);
  }

  function showPerson(person, index, selectedPerson, setSelectedPerson){
    return (
      <div className={index === selectedPerson ? "singlePersonSelected" : "singlePerson"} onClick={() => {
        if(index === selectedPerson){
          setAddFriendModel(1);
        }
        else{
          setSelectedPerson(index);
        }
      }} key={"key" + index}>
        <div className="locationGrid">
          <div className="perImg">
            <img className="resizePerson" src={BackendUrl + person.photo} alt=""/>
          </div>
          <p style={{width: 220}}>{person.firstname + " " + person.lastname}</p>
        </div>
      </div>
    );
  }

  function removeFriend(person){
    fetchLocalisa(token, "friends?friendID=" + person.id, ()=> {
      setSelectedPersonReq(-1);
      setFriendRequests(friendRequestsTmp.filter(person_ => {
        return person.id !== person_.id;
      }));
    }, setError, null, setLoading, "DELETE");
  }

  function showAcceptModal(){
    return showModal(null,
      () => {
        setSelectedPersonReq(-1);
        removeFriend(friendRequestsTmp[selectedPersonReq]);
      },
      t(accept_friend_1) + " " +
      friendRequestsTmp[selectedPersonReq].firstname + " " +
      friendRequestsTmp[selectedPersonReq].lastname + "'s " +
      t(accept_friend_2),
      t(accept_t),
      t(decline_t),
      () => {
        confirmFriend(setIsError, setLoading, friendRequestsTmp[selectedPersonReq].id);
      });
  }

  function showAddModal(){
    return showModal(setAddFriendModel,
      () => {
        setAddFriendModel(0);
      },
      t(add_as_friend),
      t(adD_t),
      t(cancel_t),
      () => {
        addFriend(foundPersons[selectedPerson].id);
        setAddFriendModel(0);
        setHiddenPersons((prev) => [foundPersons[selectedPerson].id, ...prev]);
        setSelectedPerson(-1);
      });
  }

  return (
    <div>
      {selectedPersonReq > -1 ? showAcceptModal() : null}
      {addFriendModel ? showAddModal() : null}
      {isError ? <div className="alert">error occurred</div> : null}

      {
        friendRequestsTmp.length > 0 ?
          <div>
            <h1>{t(requests_t)}</h1>
            <div className="location-el">
              <div className="locationGrid">
                {
                  friendRequestsTmp.map((person, index) => {
                    return showPerson(person, index, -1, setSelectedPersonReq);
                  })
                }
              </div>
            </div>
          </div> : null
      }


      <h1>{t(search_new_friends)}</h1>

      <input className="searchfield" placeholder={t(name_or_email)} value={searchValue.length ? searchValue : ""}
             onChange={e => setSearchValue(e.target.value)}/>
      <br/>
      <button className="buttonSq"
              onClick={updateSearch}>{t(search_t)}
      </button>
      <br/>


      <br/>
      <div className="location-el">
        <div className="locationGrid">
          {
            foundPersons.length > 0 ?
              <div>
                <h2>{t(new_people_to_connect)}</h2>
                {foundPersons.map((person, index) => {
                  if(!friends.some((p) => p.id === person.id) && !hiddenPersons.some((p) => p === person.id)){
                    return showPerson(person, index, selectedPerson, setSelectedPerson);
                  }
                  return false;
                })}
              </div> :
              <div>
                <p>{t(enter_existing_name_email)}</p>
                <p style={{fontSize: 100}}>&#128540;</p>
              </div>
          }
        </div>
      </div>
      {selectedPerson >= 0 && helpers ? <div>
        <img className="helperMiddleButtonIcon" src={helperIcon} alt={""}/>
        <label className="helper helperMiddleButton">{t(add_t)}</label>
      </div> : null}

    </div>
  );

}
