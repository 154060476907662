import * as React from 'react';
import {useEffect, useState} from "react";

import RatingWheel, {ratingsName} from "./RatingWheel";

import './components.css';

export default function RatingEdit(props){
  const [selectedKnob, setSelectedKnob] = useState(0);

  const updateRatings = (k, v) => {
    props.setRatings(new Map(props.ratings.set(k, v)));
  };

  useEffect(() => {
    let arr = [];
    if(props.location && !isNaN(props.location.ratings.myratings?.ratings)){
      arr = props.location.ratings.myratings.ratings.split("");
    }
    else{
      arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }
    ratingsName.map(function (object, i){
      return updateRatings(object, arr[i]);
    });

  }, []);

  if(!props.ratings.size){
    return (<div/>);
  }

  return (
    <div>
      <div className="scrollBarBlur">
        <div className="shadow">{"<"}</div>
        <div className="ratingEdit">
          {
            ratingsName.map(function (object, i){
              return (<div onClick={() => setSelectedKnob(i)} key={"wheelwraper" + object}>
                <label className="nameTinyWheel"> {object} </label>
                <RatingWheel id={"id:" + object} classN="wheelT" width={80} thickness={0.22} angleOffset={10}
                             value={props.ratings.get(object)} trigger={selectedKnob}/>
              </div>);
            })}

        </div>
        <div className="shadow">{">"}</div>
      </div>
      <div className="ratingWheelEdit">
        <div className="nameEditingWheel"> {ratingsName[selectedKnob]} </div>
        <RatingWheel id="editingWheel" classN="wheelT" width={200} thickness={0.17} angleOffset={10}
                     editable={true} updateValue={(value) => {
          updateRatings(ratingsName[selectedKnob], value);
        }} value={props.ratings.get(ratingsName[selectedKnob])} trigger={selectedKnob}/>
      </div>
    </div>
  );
}

RatingEdit.propTypes = {};
