import * as React from 'react';

import './components.css';

import {useContext, useEffect, useState} from "react";
import plusImage from "../assets/middlePlus.png";
import UploadImages from "./myPictureInput";
import {
  cancel_t, email_verification_needed,
  friends_more, hint_email_verify, logout_t,
  save_t,
  t,
  upload_t,
  you_dont_have_any_friends,
  your_connected_people
} from "./languages";
import helperIcon from "../assets/helperIcon.png";
import jwt_decode from "jwt-decode";
import {BackendUrl, postDataToUrl} from "./API";
import {LoadingContext, LocalisaContext} from "../App";
import {ContentContext} from "./ContentScreen";
import {getUrlForImage} from "./Utils";

function showNoFriends(setContent, setBuffer){
  return (
    <div className="location-el" id="signup">
      <p className="infoNew">{t(your_connected_people)}</p>
      <div className="button">
        <img className="img" src={plusImage} onClick={() => {
          setContent(8);
          setBuffer("");
        }} alt={""}/>
      </div>
      <p id="add" className="infoNew">{t(you_dont_have_any_friends)}</p>
    </div>
  );
}

function showPerson(setContent, setBuffer, person, confirmed = true){
  return (
    <div className="singlePerson"
         key={"id_"+person.id}
         onClick={() => {
           setBuffer(person);
           setContent(9);
         }}>
      <div className="locationGrid" >
        <div className={confirmed ? "perImg" : "perImg shadowRed bg"}>
          <img className="resizePerson" src={BackendUrl + person.photo} alt={person.firstname+"ProfilPic"}/>
        </div>
        <p style={{width: 220}}>{person.firstname + " " + person.lastname}</p>
      </div>
    </div>
  );
}

function showFriends(setContent, setBuffer, persons){
  return (
    <div className="location-el">
      <div className="locationGrid">
        {persons.map((person) => {
          return showPerson(setContent, setBuffer, person, person.email);
        })}
      </div>
    </div>
  );
}

export default function Persons({setBuffer, friends, friendsRequested, friendRequests, ego}){
  const [isError, setIsError] = useState(false); //todo one error for all
  const [isWarning, setIsWarning] = useState(false); //todo move to contentscreen and pop up always
  const [friendsShown, setFriendsShown] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [image, setImage] = useState([]);
  const [editPic, setEditPic] = useState(0);
  const [photoEgo, setPhotoEgo] = useState(ego.photo);
  const [imageOrientations, setImagerOrientations] = useState("land");
  const helpers = localStorage.getItem("helpers");
  const token = useContext(LocalisaContext);
  const setContent = useContext(ContentContext)
  const setLoading = useContext(LoadingContext)

  useEffect(() => {
    if(jwt_decode(token).expire){
      setIsWarning(true);
    }
    if(document.getElementById("image0")){
      let array = imageOrientations;
      let h = document.getElementById("image0").offsetHeight;
      let w = document.getElementById("image0").offsetWidth;
      if(h > w){
        array = "por";
      }
      setImagerOrientations(array);

    }
    let friends_=friends && friendsRequested ?
      [...friends, ...friendsRequested]:
      friends?
        friends:
        friendsRequested?
          friendsRequested:
          []
    setFriendsShown(friends_)
  },[]);

  function update(){
    let data = new FormData();
    data.append("image", image[0]);

    postDataToUrl(token, "users/edit",
      data, (user)=> {
        setEditPic(0);
        setPhotoEgo(user.photo);
        window.location.reload()
      }, null, setLoading);
  }

  return (
    <div>
      {isWarning ?
        <div className="alert" style={{position: "absolute", backgroundColor: "white"}}>
          {t(email_verification_needed) + Math.round((Date.parse(jwt_decode(token).expire) - (Date.now())) / 3600000) + "h"}
          <div className="alert" style={{fontSize: 10}}>{t(hint_email_verify)}</div>
        </div> :
        null}

      {isError ? <div className="alert">
          Sorry, was not able to update your picture.
        </div> :
        <div>
          <div className="profilPicContainerWithName">
            {editPic ?
              <div>
                <UploadImages givenImages={[]} setGivenImages={setImage} single={true}/>
                <button className="buttonSq" onClick={update}>{t(save_t)}</button>
                <br/>
                <button className="buttonSq bgRed" onClick={() => setEditPic(0)}>{t(cancel_t)}</button>
              </div> :
              <div>
                <div className="profilPicContainer">
                  <img className={imageOrientations === "land" ? "resizeSlideshowImage" : "resizeSlideshowImagePor"}
                       src={getUrlForImage(photoEgo)} id={"image0"} alt={""}/>
                </div>

                <div className="uploadPicT" onClick={() => setEditPic(1)}>{t(upload_t)}</div>
                <h2> {ego.firstname} {ego.lastname} </h2>
              </div>}
          </div>
        </div>}


      <hr/>


      <div>
        <h1>{t(friends_more)}
          <div className="addPersonButton">
            <img className="img" onClick={() => {
              setContent(8);
              setBuffer(searchValue);
            }} src={plusImage} alt={""}/>
            {friendRequests?.length ? <div className="overlayRequests">
              <span className="overlayRequests" id="requestsCounter">{friendRequests.length}</span>
            </div> : null}
          </div>
        </h1>

        {/*/!*<input placeholder={t(search_friends)} className="searchfield" onChange={e => search(e.target.value)}/>*!/ Todo gescheid und mit leerzeichen*/}
        {friendsShown?.length === 0 ? showNoFriends(setContent, setBuffer) : showFriends(setContent, setBuffer, friendsShown)}

      </div>

      {helpers ? <div>
        <img className="helperMiddleButtonIcon" src={helperIcon} alt={""}/>
        <label className="helper helperMiddleButton">{t(logout_t)}</label>

      </div> : null}
    </div>
  );

}

