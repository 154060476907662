import axios from "axios";

// export const BackendUrl = "http://localhost:8080/";
export const BackendUrl = "https://localisa.app/backend/";

function stdCatch(response){
  console.log("error fetching or posting data:");
  console.log(response);
  console.log("error occured at:");
  console.trace();
}

export function fetchDataFromExtUrl(url, thenCB, catchCB = stdCatch, setLoading = null){
  fetchLocalisa("", url, thenCB, catchCB, null, setLoading, "GET", true);
}

export function fetchDataFromUrl(token, url, thenCB, catchCB = stdCatch, setLoading = null){
  fetchLocalisa(token, url, thenCB, catchCB, null, setLoading, "GET");
}

export function postDataToUrl(token, url, data, thenCB, catchCB = stdCatch, setLoading = null){
  fetchLocalisa(token, url, thenCB, catchCB, data, setLoading, "POST");
}

export function fetchLocalisa(token, url, thenCB, catchCB = stdCatch, data = null, setLoading = null, method = null, extern = null, getPromise = null){
  if(setLoading){
    setLoading(1);
  }

  if(!method && data){
    method = "POST";
  }
  else if(!method){
    method = "GET";
  }

  if(!extern){
    url = BackendUrl + url;
  }

  const promise = fetch(url, {
    method: method,
    headers: new Headers({
      'Authorization': 'Bearer ' + token
    }),
    body: data
  })
    .then(response => response.json())
    .then(data => {
      if(!data["returnCode"] || data["returnCode"] < 299){
        return thenCB(data);
      }
      else{
        return catchCB(data["errorMessage"]);
      }
    })
    .catch(catchCB)
    .finally(() => {
      if(setLoading){
        setLoading(0);
      }
    });

  if(getPromise){
    return promise;
  }
  else{
    return "";
  }

}

export function fetchLocalisaIMG(token, url, catchCB = stdCatch, setLoading = null){
  if(setLoading){
    setLoading(1);
  }
  const method = "GET";

  return fetch(url, {
    method: method,
    headers: new Headers({
      'Authorization': 'Bearer ' + token
    })
  })
    .then(response => {
      if(response.status < 299 && response.status >= 200){
        return response.blob().then((pic) => {
          return URL.createObjectURL(pic);
        });
      }
      else{
        return catchCB(response["errorMessage"]);
      }
    })
    .catch(catchCB)
    .finally(() => {
      if(setLoading){
        setLoading(0);
      }
    });

}

export function uploadImage(token, image, updateImagesUploadProgress, thenCB, catchCB = stdCatch){
  let data = new FormData();
  data.append("image", image);

  axios.post(BackendUrl + "uploads", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      'Authorization': 'Bearer ' + token
    },
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total);
      updateImagesUploadProgress(image.name, progress);
    }
  })
    .then(response => {
      if(!response.data["returnCode"] || response.data["returnCode"] < 299){
        return thenCB(response.data);
      }
      else{
        return catchCB(response.data["errorMessage"])
      }
    })
    .catch(catchCB)
}
