import * as React from 'react';
import 'leaflet/dist/leaflet.css';
import {View} from "react-native-web";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import Menu from "./Menu";
import middlePlusIcon from "../assets/middlePlus.png";
import middleSaveIcon from "../assets/middleSave.png";
import middleSearchIcon from "../assets/middleSearch.png";
import middleEditIcon from "../assets/middleEdit.png";
import middleLogout from "../assets/middleLogout.png";
import middleEmail from "../assets/middleMail.png";
import Map from "./Map";
import Newsfeed from "./Newsfeed";
import Add from "./Add";
import Location from "./Location";
import Persons from "./Persons";
import Impressum from "./Impressum";
import AddPerson from "./AddPerson";
import Person from "./Person";
import {getCookieConsent} from './Utils';
import {getCookieConsentValue} from "react-cookie-consent";
import {fetchLocalisa} from "./API";
import {ESMContext, LoadingContext, LocalisaContext} from "../App";

export const ContentContext = React.createContext(null);

/*
idea content:
  1 -> friends/profil
  2 -> map
  3 -> newsfeed
  4 -> impressum
  5 -> add location
  6 -> show location
  7 -> edit location
  8 -> add Person
  9 -> show Person
 */

function logout(){
  localStorage.clear();
  window.location.reload();
}

export default function ContentScreen({idUser}){
  const [data, setData] = useState({});
  const [contentShown, setContentShown] = useState(localStorage.getItem('contentShown') ? parseInt(localStorage.getItem('contentShown')) : 2);
  const contentScreenFunction = useRef(null);
  const [buffer, setBuffer] = useState([]);
  const setWarningRef = useRef(null);
  const token = useContext(LocalisaContext);
  const setLoading = useContext(LoadingContext);
  const setError = useContext(ESMContext)

  console.log("render content");

  function setContent(c){
    if(c > 100){
      setData(old => {
        return {
          ...old,
          last_fetched: 0
        };
      });
      c -= 100;
    }

    if(getCookieConsentValue("acceptCookies") === "true" && c < 6){
      localStorage.setItem('contentShown', c);
    }
    setContentShown(c);
  }

  function updateData(position){
    setData(old => {
      return {
        ...old,
        curPos: position.coords ? [position.coords.latitude, position.coords.longitude] : [0, 0]
      };
    });
  }

  useEffect(() => {
    if(!data.last_fetched || (!([4, 5, 6, 7, 8, 9].includes(contentShown)) && (!data.last_fetched || Date.now() - data.last_fetched > 1e4))){
      const locProm = fetchLocalisa(token, "locations", data=>data, setError, null, setLoading, null, null, true);
      const perProm = fetchLocalisa(token, "friends", data=>data, setError, null, setLoading, null, null, true);
      const egoProm = fetchLocalisa(token, "user", data=>data, ()=>{logout()}, null, setLoading, null, null, true);

      Promise.all([egoProm, perProm, locProm])
        .then((data) => {
          console.log("fetched: location, friends and ego");
          setData({
            ego: data[0],
            friends: data[1].friends,
            friendsRequested: data[1].friendsRequested,
            friends_requests: data[1].requests,
            my_locations: data[2].mylocations,
            locations_friends: data[2].friendslocations,
            last_fetched: Date.now()
          });
          navigator.geolocation.getCurrentPosition(updateData, updateData);
        });
    }
    else{
      console.log("lazy");
    }
  }, [contentShown, idUser, token]);

  function Content(){
    switch(contentShown){
      case 3:
        return <Newsfeed data={data} setLocation={setBuffer} filterRef={contentScreenFunction}/>;
      case 2:
        return <Map data={data} submit={contentScreenFunction} setPin={setBuffer}/>;
      case 5:
        return <Add submit={contentScreenFunction} pin={buffer} setWarningRef={setWarningRef} curPos={data.curPos}/>;
      case 6:
        const owner=data.friends.filter((friend) => friend.id === buffer.user)[0]
        return <Location location={buffer} submit={contentScreenFunction} owner={owner}
                         setBuffer={setBuffer} friends={data.friends}/>;
      case 7:
        return <Add submit={contentScreenFunction} locationGiven={buffer} setWarningRef={setWarningRef}
                    curPos={data.curPos}/>;
      case 1:
        return <Persons setBuffer={setBuffer} friends={data.friends} friendsRequested={data.friendsRequested}
                        friendRequests={data.friends_requests} ego={data.ego}/>;
      case 4:
        return <Impressum/>;
      case 8:
        return <AddPerson submit={contentScreenFunction} buffer={buffer} friends={data.friends}
                          friendRequests={data.friends_requests}/>;
      case 9:
        return <Person submit={contentScreenFunction} person={buffer}
                       locationsFriends={data.locations_friends} setLocation={setBuffer}/>;
      default:
        return (<div>
          other screen
        </div>);
    }
  }

  function MainMenu(){
    switch(contentShown){
      case 3:
        return <Menu middleFunction={() => contentScreenFunction.current()} middleIcon={middleSearchIcon}/>;
      case 2:
        return <Menu middleFunction={() => contentScreenFunction.current()} middleIcon={middlePlusIcon}/>;
      case 5:
        return <Menu middleFunction={() => contentScreenFunction.current()} middleIcon={middleSaveIcon}
                     menuSelection1={(e) => setWarningRef.current(e)}/>;
      case 6:
        return <Menu middleFunction={() => buffer.user !== idUser ? contentScreenFunction.current() : setContent(7)}
                     middleIcon={middleEditIcon} />;
      case 7:
        return <Menu middleFunction={() => contentScreenFunction.current()} middleIcon={middleSaveIcon}
                     menuSelection1={(e) => setWarningRef.current(e)}/>;
      case 1:
        return <Menu middleFunction={logout} middleIcon={middleLogout}/>;
      case 4:
        return <Menu middleFunction={() => window.location = 'mailto:info@localisa.app'} middleIcon={middleEmail}/>;
      case 8:
        return <Menu middleFunction={() => contentScreenFunction.current()} middleIcon={middlePlusIcon}
                     />;
      case 9:
        return <Menu middleFunction={() => contentScreenFunction.current()} middleIcon={middleEmail}/>;
      default:
        return <Menu  middleIcon={middleEmail} middleFunction={() => {}}/>;
    }
  }

  let content = useMemo(() => {
    return (<Content/>);
  }, [data, contentShown]);

  return (
    <View>
      <ContentContext.Provider value={setContent}>
        {data.curPos ?
          <View>
            {content}
          </View> :
          null
        }
        <MainMenu/>
      </ContentContext.Provider>
      {getCookieConsent(() => setBuffer([""]))}
    </View>
  );
}
