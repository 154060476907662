import * as React from 'react';
import {useContext, useEffect, useState} from "react";

import './components.css';
import 'react-slideshow-image/dist/styles.css';
import {showLocationsWithRating} from './Utils';
import {latest_locations, no_location_added, not_yet_friends, remove_friend, t} from "./languages";
import {BackendUrl, fetchLocalisa} from "./API";
import {LoadingContext, LocalisaContext} from "../App";
import {ContentContext} from "./ContentScreen";

export default function Person({submit, person, locationsFriends, setLocation}){
  const itsLocations = locationsFriends.filter((location) => {
    return location.user === person.id;
  });
  const [imageOrientations, setImagerOrientations] = useState("land");
  const token = useContext(LocalisaContext);
  const setContent = useContext(ContentContext)
  const setLoading = useContext(LoadingContext)

  if(!person){
    console.log("no location given, maybe load it first");
  }

  useEffect(() => {

    if(document.getElementById("image0")){
      let array = imageOrientations;
      let h = document.getElementById("image0").offsetHeight;
      let w = document.getElementById("image0").offsetWidth;
      if(h > w){
        array = "por";
      }
      setImagerOrientations(array);
    }
    submit.current = () => {
      if(person.email){
        window.location = 'mailto:' + person.email;
      }

    };
  });

  function onClickFunction(location){
    setLocation(location);
    setContent(6);
  }

  function removeFriend(){
    fetchLocalisa(token, "friends?friendID=" + person.id, ()=>setContent(101), null, null, setLoading, "DELETE");
  }

  return (<div>
      {/*Photo*/}
      <div className="resizeSlideshow">
        <img className={imageOrientations === "land" ? "resizeSlideshowImage" : "resizeSlideshowImagePor"}
             src={BackendUrl + person.photo} id={"image0"} alt={""}/>
      </div>

      <h1 className="text-align-left margin-left">{person.firstname + " " + person.lastname}</h1>
      <div className="text-align-left margin-left">
        <br/>
        <span className="font-weight-bold">E-Mail:</span>
        <br/>
        {person.email ? person.email : person.firstname + t(not_yet_friends)}
      </div>
      <div className="text-align-left margin-left">
        <br/>
        <span className="font-weight-bold">{t(latest_locations)}</span>
      </div>
      {itsLocations.length === 0 ? <p className="location-el">{person.firstname} {t(no_location_added)}
        :(</p> : showLocationsWithRating(onClickFunction, itsLocations)}
      <button className="buttonSq bgRed" onClick={removeFriend}>{t(remove_friend)}</button>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>

    </div>

  );

}
