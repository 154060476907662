import RatingWheel from "./RatingWheel";
import * as React from "react";
import {
  accept_t, activity_t, anchor_t, bar_t, bay_t, bouy_t, cafe_t, club_t,
  continue_t, cookie_banner, decline_t,
  discard_t, flight_t,
  has_been_at, hiking_t,
  no_locations_found, parking_t, password_t,
  reset_filter, shop_t,
  show_t, sight_t,
  t, view_t
} from "./languages";
import {MapContainer, Marker, useMap} from "react-leaflet";
import posIcon from "../assets/standort.png";
import {useMemo} from "react";
import L from 'leaflet';
import 'leaflet.tilelayer.colorfilter'; //wichtig nach import leaflet
import locateIcon from "../assets/mapIcon.png";
import standortPin from "../assets/mapPinNew.png";
import CookieConsent, {getCookieConsentValue} from "react-cookie-consent";
import hidePasswordIcon from "../assets/visible.png";
import showPasswordIcon from "../assets/invisible.png";
import helperIcon from "../assets/helperIcon.png";
import LoadingSpinner from "react-loading";
import {BackendUrl} from "./API";

export const locationCategories = [
  {value: 'todo', label: 'ToDo'},
  {value: 'restaurant', label: 'Restaurant'},
  {value: 'hotel', label: 'Hotel'},
  {value: 'camping', label: 'Camping'},
  {value: 'park', label: 'Park'},
  {value: 'shop', label: t(shop_t)},
  {value: 'cafe', label: t(cafe_t)},
  {value: 'activity', label: t(activity_t)},
  {value: 'bar', label: t(bar_t)},
  {value: 'club', label: t(club_t)},
  {value: 'parking', label: t(parking_t)},
  {value: 'sight', label: t(sight_t)},
  {value: 'flight', label: t(flight_t)},
  {value: 'bay', label: t(bay_t)},
  {value: 'anchor', label: t(anchor_t)},
  {value: 'buoy', label: t(bouy_t)},
  {value: 'trail', label: t(hiking_t)},
  {value: 'view', label: t(view_t)}
];

function showLocName(location, owner, showLocationName){
  return <>
    {location.user && owner ? <span
      style={{fontSize: 10, fontWeight: "bold", marginTop: 5}}>{owner[0].firstname} {t(has_been_at)}</span> : null}
    <span style={{fontSize: 16, fontWeight: "bold"}}>{location.name}</span>
    {showLocationName && location.city ? <span
      style={{fontSize: 8, fontWeight: "bold"}}><img style={{height: 7}}
                                                     src={standortPin} alt={""}/> {location.city}</span> : null}
  </>;
}

function showLocationWithRating(onClickFunction, location, myRating, showLocationName, owner){
  let count = 0;
  let arr = location.ratings.itsratings?.ratings?.split("");

  if(!arr)
    arr = location.ratings.myratings?.ratings?.split("")

  if(!arr)
    arr = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]

  if(arr[0] > 0){
    count = 1;
  }

  const ratingOverAll = arr.reduce((prev, item) => {
    if(item > 0){
      count++;
    }
    return Number(prev) + Number(item);
  }) / count;

  return (<div className="singleLocPerson" key={location.idlocation} onClick={() => {
    onClickFunction(location);
  }}>
    <div className="locWheelAndImg">
      <div className="locImgPerson">
        <img className="resizeLocPerson" src={getUrlForImage(location["photo"].split(";")[0], location.idlocation)} alt={""}/>
      </div>
      {showLocName(location, owner, showLocationName)}
      <RatingWheel id={"locID:" + location["idlocation"] + "myRating:" + myRating} width={90} thickness={0.14}
                   value={isNaN(ratingOverAll) ? 0 : ratingOverAll} classN="wheelRatingOffset10"/>
    </div>
  </div>);
}

export function showLocationsWithRating(onClickFunction, locations, persons = null, callback = null, myRating = false){
  const showLocationName = localStorage.getItem("locNewsDefault") ? localStorage.getItem("locNewsDefault") === t(show_t) : true;
  const userID = localStorage.getItem("userId")

  if(!locations.length){
    return (
      <div className="location-el">
        <div className="locationGrid">
          {t(no_locations_found)}
          {callback ? <button className="buttonSq bgRed" onClick={callback}> {t(reset_filter)}</button> : null}
        </div>
      </div>
    );
  }

  return (
    <div className="location-el">
      <div className="locationGrid">
        {locations.reverse().map((location) => {
          return showLocationWithRating(onClickFunction, location, myRating, showLocationName, persons && location.user !== parseInt("" + userID) ? persons.filter((person) => person.id === location.user) : null);
        })}
      </div>
    </div>
  );
}

export function ratingToString(ratings){
  if(ratings.size){
    return Array.from(ratings.values()).reduce((prev, item) => {
      return `${prev}${item}`;
    }) + "0".repeat(30 - ratings.size);
  }
  else{
    return "0".repeat(30);
  }
}

export function ToggleSwitch({categories, setCategory, category, className = null}){
  const classN = categories.length === 2 ? "toggleSwitch width-160" : "toggleSwitch";
  return <div className={className}>
    <div className={classN}>
      {categories.map((value, index) => {
        return (<div key={"toggleSwitchLabel font-weight-bold" + index}>
          <label className="toggleSwitchLabel font-weight-bold" onClick={(e) => {
            setCategory(e.target.innerHTML);
          }}>{value}</label>
        </div>);
      })}
    </div>
    <div className={classN + " toggleSwitcher togglerPos" + categories.indexOf(category)}>
      <label className={"toggleSwitchLabel font-weight-bold colorWhite "}>
        {category}
      </label>
    </div>
  </div>;
}

export function ToggleSwitchLabels({categories, className = null, categoriesDefault = null}){
  const classN = categories.length === 2 ? "toggleSwitchLabels width-160" : "toggleSwitchLabels";
  return <div className={className}>
    <div className={classN}>
      {categories.map((value, index) => {
        return (<div key={"toggleSwitchLabel" + index}>
          <label
            className={categoriesDefault && categoriesDefault[index] !== value ? "toggleSwitchLabel activeFilter" : "toggleSwitchLabel"}>{value}</label>
        </div>);
      })}
    </div>
  </div>;
}

function getPosIcon(){
  return L.icon({
    iconUrl: posIcon,
    iconSize: 20
  });
}

function AddMapLayer(props){
  const map = useMap();
  map.dragging.disable();
  map.doubleClickZoom.disable();
  map.scrollWheelZoom.disable();
  map.keyboard.disable();
  map.touchZoom.disable();

  map.setView(props.position, map.getZoom());

  useMemo(() => {

    let myFilter = [
      'blur:0px',
      'brightness:100%',
      'contrast:100%',
      'grayscale:100%',
      'hue:300deg',
      'opacity:100%',
      'invert:0%',
      'saturate:100%',
      'sepia:0%'
    ];
    L.tileLayer.colorFilter('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      filter: myFilter
    }).addTo(map);
  }, [map]);

  return (<div className="leaflet-top leaflet-right">
    <img className="leaflet-control  iconSpaced bgDarkBlue" style={{width: 20, height: 20, borderRadius: 12}}
         src={locateIcon} onClick={() => {
      props.setContent(2);
      if(getCookieConsentValue("acceptCookies") === "true"){
        localStorage.setItem("centerMap", props.position[0] + ";" + props.position[1]);
        localStorage.setItem("zoomMap", map.getZoom());
      }
    }} alt={""}/>
  </div>);

}

export function MiniMap(props){
  return <MapContainer
    center={props.curPos}
    zoom={13}
    style={{height: "120px", width: "100wh"}}
    attributionControl={false}
    className="unselectable miniMap"
  >
    <AddMapLayer position={props.curPos} setContent={props.setContent}/>
    <Marker position={props.curPos} icon={getPosIcon()}/>
  </MapContainer>;
}

export function esc(string){
  const emojiRegex = require('emoji-regex');
  const regex = emojiRegex();

  if(string && string.length){
    string = string.replace("'", "\\'");
    string = string.replace(regex, "{emoji removed}");
    return string.replace('"', '\\"');
  }
  return "";

}

export function showModal(setShowWarning, callbackOnDiscard, text, acceptText = t(continue_t), discardText = t(discard_t), callbackOnAccept = null){
  return <div className="loadingSpinnerBackground">
    <div className="location-el modal">
      <p>{text}</p>
      <button className="buttonSq"
              onClick={callbackOnAccept ? callbackOnAccept : () => {
                setShowWarning(0);
              }}>{acceptText}
      </button>
      <button className="buttonSq alert" onClick={callbackOnDiscard} hidden={!discardText}>{discardText}</button>
    </div>
  </div>;
}

export function navigateTo(lon, lat){
  if(!lon && !lat){
    return;
  }
  if(navigator.userAgent.includes("iPhone") || navigator.userAgent.includes("iPad") || navigator.userAgent.includes("iPod")){
    window.open("maps://maps.google.com/maps?daddr=" + lat + "," + lon + "&amp;ll=");
  }
  else /* else use Google */{
    window.open("https://maps.google.com/maps?daddr=" + lat + "," + lon + "&amp;ll=");
  }
}

export function parseJwt(token){ //unused
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c){
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function getCookieValue(a){
  const b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
}

export function getCookieConsent(callback = null){
  return <CookieConsent
    location="bottom"
    buttonText={t(accept_t)}
    declineButtonText={t(decline_t)}
    cookieName="acceptCookies"
    style={{background: "white", zIndex: 4000}}
    onAccept={callback}
    onDecline={() => {
      localStorage.clear();
      document.cookie = 'acceptCookies=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }}
    enableDeclineButton flipButtons
  >{t(cookie_banner)}</CookieConsent>;
}

export function PasswordInput({showPassword, setPassword, setShowPassword, autoComplete, placeholder = t(password_t)}){
  return <div className="input_container">
    <input className="input" placeholder={placeholder} type={showPassword ? "text" : "password"}
           onChange={e => setPassword(e.target.value)} autoComplete={autoComplete}/>
    {setShowPassword ?
      <img src={showPassword ? hidePasswordIcon : showPasswordIcon} id="img_input"
           onClick={() => showPassword ? setShowPassword(0) : setShowPassword(1)}
           alt="show password in cleartext button"/> :
      null}
  </div>;
}

export function HelperMiddleButton({text}){
  if(localStorage.getItem("helpers") === "1"){
    return <div>
      <img className="helperMiddleButtonIcon" src={helperIcon} alt={""}/>
      <label className="helper helperMiddleButton">{text}</label>
    </div>;
  }
  else{
    return null;
  }
}

export function searchInPersons(friends, searchValue, setSearchResult, standard){
  let result = friends.filter((person) => {
    if(searchValue !== ""){
      return person.firstname.toLowerCase().includes(searchValue.toLowerCase());
    }
    return false;
  });
  let result_ = [];
  result.map((element) => {
    return result_.push(element.firstname + " " + element.lastname + ";" + element.id);
  });
  result_.push(standard);
  setSearchResult(result_);
}

export function MyLoadingSpinner({isLoading}){
  if(isLoading > 0){
    return <div className="loadingSpinnerBackground">
      <LoadingSpinner className="loadingSpinner" type={"spin"} width={"50px"} color={"#2c3e50"}/>
    </div>;
  }
  if(isLoading < 0){
    return <LoadingSpinner className="loadingSpinner" type={"spin"} width={"50px"} color={"#2c3e50"}/>;
  }
  return null;
}

export function buildOverpassAPI(overpassQuery, bounds){
  let nodeQuery = 'node[' + overpassQuery + '](' + bounds + ');';
  // let wayQuery = 'way[' + overpassQuery + '](' + bounds + ');';
  // let relationQuery = 'relation[' + overpassQuery + '](' + bounds + ');';
  let query = '?data=[out:xml][timeout:25];(' + nodeQuery /*+ wayQuery + relationQuery*/ + ');out body;>;out skel qt;';
  let baseUrl = 'https://overpass-api.de/api/interpreter';
  return baseUrl + query;
}

export function buildOverpassApiUrl(map, overpassQuery){
  let bounds = map.getBounds().getSouth() + ',' + map.getBounds().getWest() + ',' + map.getBounds().getNorth() + ',' + map.getBounds().getEast();
  console.log(bounds);
  return buildOverpassAPI(overpassQuery, bounds);
}


export function Impressum(){
  return <>
    &#8226;
    <br/>
    <a href="https://localisa.app/datenschutz"
       target="_blank" rel="noreferrer"> {"Datenschutz"}</a>
    {" "}
    <a href="https://localisa.app/impressum"
       target="_blank" rel="noreferrer"> {"Impressum"}</a>
    <br/>
  </>;
}


export function resizeImages(pic, setImages, single){
  let image = new Image();

  function saveImage(img){
    if(single){
      setImages([new File([img], pic.name, {
        lastModified: new Date().getTime(),
        type: img.type
      })]);
    }
    else{
      setImages(old => [...old, new File([img], pic.name, {
        lastModified: new Date().getTime(),
        type: img.type
      })]);
    }
  }

  image.onload = function (){
    let canvas = document.createElement('canvas');
    let maxSize = 600;
    let width = image.width;
    let height = image.height;

    if(width > height){
      if(width > maxSize){
        height *= maxSize / width;
        width = maxSize;
      }
    }
    else{
      if(height > maxSize){
        width *= maxSize / height;
        height = maxSize;
      }
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
    canvas.toBlob(saveImage);
  };
  image.src = URL.createObjectURL(pic);
}


export function getUrlForImage(slideImage, idlocation = null){
  if(slideImage.includes("uploads/"))
    return BackendUrl+slideImage
  const idUser = localStorage.getItem("userId");
  let url = BackendUrl + "uploads/" + idUser + "/";
  if(idlocation)
    url = url + idlocation + "/";
  url = url + slideImage;
  return url
}