import {useEffect, useState} from 'react';

import './components.css';
import {
  add_new_location_failed,
  add_rating_to_location_failed,
  add_rating_to_new_location_failed,
  email_or_password_wrong,
  email_verification_error,
  enter_email, invalid_token,
  login_failed, name_of_location_missing, note_minimum_lengts,
  password_reset_email_sent, passwords_do_not_match, sending_email_failed,
  t, update_location_failed, your_email_exists_already, your_email_is_not_valid
} from "./languages";

const errorMessages = {
  "sending email failed" : [t(sending_email_failed), "errorFieldSignIn"],
  "wrong password" : [t(login_failed)+t(email_or_password_wrong), "errorFieldSignIn"],
  "invalid email format" : [t(login_failed)+t(email_or_password_wrong), "errorFieldSignIn"],
  "no user found" : [t(login_failed)+t(email_or_password_wrong), "errorFieldSignIn"],
  "token expired" : [t(login_failed)+t(email_verification_error), "errorFieldSignIn"],
  "token invalid" : [t(login_failed)+t(invalid_token), "errorFieldSignIn"],
  "email to short" : [t(enter_email), "errorFieldSignIn"],
  "password reset email sent" : [t(password_reset_email_sent), "notificationFieldSignIn"],

  "passwords do not match" : [t(passwords_do_not_match), "errorFieldSignUp1"],
  "Duplicate entry" : [t(your_email_exists_already), "errorFieldSignUp"],
  "invalid email format for signup" : [t(your_email_is_not_valid), "errorFieldSignUp"],
  "invalid input" : [t(note_minimum_lengts), "errorFieldSignUp1"],

  "add rating to new location failed":[t(add_rating_to_new_location_failed), "errorFieldAdd"],
  "add rating to location failed":[t(add_rating_to_location_failed), "errorFieldAdd"],
  "add new location failed":[t(add_new_location_failed), "errorFieldAdd"],
  "save location failed":[t(update_location_failed), "errorFieldAdd"],
  "name of location missing":[t(name_of_location_missing), "errorFieldAdd"],
  //"delete location failed"

  //location.js
  //"no rating found"

  //impressum.js
  //"wrong password"
}

export default function ESM({setErrorRef}){
  const [error, setError] = useState()
  const [shownError, setShownError] = useState()

  useEffect(()=>{
    setErrorRef.current = setError;
  },[setErrorRef])

  useEffect(()=>{
    console.log(error)

    if(!error && shownError){
      shownError.innerHTML = "";
      return
    }

    if (errorMessages[error] !== undefined){
      const field = document.getElementById(errorMessages[error][1]);
      if (field){
        if(shownError)
          shownError.innerHTML = ""
        field.innerHTML = errorMessages[error][0]
        setShownError(field)
      }
    }
    else if(error === "internal server error"){
      localStorage.clear();
      window.location.reload();
    }


  }, [error, shownError])

  return null

}