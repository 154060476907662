import * as React from 'react';
import {useContext, useEffect, useRef, useState} from "react";

import './components.css';
import 'react-slideshow-image/dist/styles.css';
import {Slide} from "react-slideshow-image";
import RatingWheel, {ratingsName} from "./RatingWheel";
import phoneIcon from '../assets/phone.png';
import websiteIcon from '../assets/web.png';
import navigateIcon from '../assets/navigation.png';
import RatingEdit from "./RatingEdit";
import {
  searchInPersons,
  MiniMap,
  navigateTo,
  ratingToString,
  ToggleSwitch, locationCategories, getUrlForImage
} from "./Utils";
import {
  added_by,
  click_for_details, comment_t, edit_location,
  friend_t,
  mine_t, not_shared_with_friends,
  overall_t,
  rate_t, save_t,
  shared_with_friends,
  t
} from "./languages";
import helperIcon from "../assets/helperIcon.png";
import {BackendUrl, fetchDataFromUrl, postDataToUrl} from "./API";
import {ESMContext, LoadingContext, LocalisaContext} from "../App";
import {ContentContext} from "./ContentScreen";

const properties = {
  duration: 5000,
  transitionDuration: 1000
};
const propertiesSingleImage = {
  autoplay: false,
  prevArrow: <div hidden={true}/>,
  nextArrow: <div hidden={true}/>,
  canSwipe: false
};

export function calcRatingOverall(location, owner){
  let count = 0;
  const arr = owner ? location.ratings.itsratings.ratings.split("") : location.ratings.myratings.ratings.split("");
  if(arr[0] > 0){
    count = 1;
  }

  return arr.reduce((prev, item) => {
    if(item > 0){
      count++;
    }
    return Number(prev) + Number(item);
  }) / count;

}

function showRating(rating, index){
  return (<div className="singleLocation" key={"ratingwheel" + index}>
    <div className="locWheelAndImg">
      <div className="locImg">
        {ratingsName[index]}
      </div>
      <RatingWheel id={"wheel:" + index} width={130} thickness={0.14} value={parseInt(rating)} trigger={rating}
                   classN="wheelRatingOffset5"/>
    </div>
  </div>);
}

export default function Location({location, submit, owner, setBuffer, friends}){
  const [showRatings, setShowRatings] = useState(false);
  const [showRatingEdit, setShowRatingEdit] = useState(false);
  const [ratingsEdit, setRatingsEdit] = useState(new Map());
  const [ratingsShown, setRatingsShown] = useState(owner ? location.ratings.itsratings.ratings.split("") : location.ratings.myratings.ratings.split(""));
  const [category, setCategory] = useState(localStorage.getItem("locDefault") ? owner ? owner.firstname : localStorage.getItem("locDefault") : t(mine_t));
  const [imageOrientations, setImagerOrientations] = useState(location.photo.split(";").map(() => "land"));
  const [showPic, setShowPic] = useState("");
  const categories = [t(mine_t), owner ? owner.firstname : t(friend_t), t(overall_t)];
  const scrollToEditLabel = useRef(null);
  const helpers = localStorage.getItem("helpers");
  const token = useContext(LocalisaContext);
  const setContent = useContext(ContentContext)
  const setLoading = useContext(LoadingContext)
  const setError = useContext(ESMContext)

  console.log(location);

  const sliderImages = location.photo.split(";");
  const ratingOverAll = calcRatingOverall(location, owner);

  function rateLocation(){
    if(!showRatingEdit){
      setShowRatingEdit(true);
    }
    else{
      submitRating();
    }

  }

  useEffect(() => {
    if(scrollToEditLabel && scrollToEditLabel.current){
      scrollToEditLabel.current.scrollIntoView(true);
    }
  }, [showRatingEdit]);

  useEffect(() => {
    submit.current = rateLocation;
  }, [showRatingEdit, ratingsEdit, submit]);

  useEffect(() => {
    if(category === t(mine_t)){
      setRatingsShown(location.ratings.myratings?.ratings.split(""));
    }
    if(category === t(friend_t) || (owner && category === owner.firstname)){
      setRatingsShown(location.ratings.itsratings?.ratings.split(""));
    }
    if(category === t(overall_t)){
      setRatingsShown(location.ratings.ratingoverall?.ratings.split(""));
    }

  }, [category]);

  useEffect(() => {
    let array = imageOrientations;
    imageOrientations.map((el, i) => {
      if(document.getElementById("image" + i)){
        let h = document.getElementById("image" + i).offsetHeight;
        let w = document.getElementById("image" + i).offsetWidth;
        if(h > w){
          return array[i] = "por";
        }
      }
      return null;
    });
    setImagerOrientations(array);
  });

  function submitRating(){
    console.log(location.idlocation);
    console.log(ratingsEdit);
    let data = new FormData();
    data.append("idlocation", location.idlocation);
    data.append("ratings", ratingToString(ratingsEdit));
    data.append("comment", "");


    postDataToUrl(token, "locations/"+location.idlocation+"/rate", data, ()=>setContent(102), setError, setLoading);

  }

  function setRatingFetched(rating){
    setRatingsShown(rating.ratings.split(""));
  }

  function setErrorAndReset(e){
    setRatingsShown("000000000000000000000000000000".split(""));
    setError(e)
  }

  function Searchbar(){
    const [searchValue, setSearchValue] = useState("");
    const [searchResult, setSearchResult] = useState([]);

    useEffect(() => {
      searchInPersons(friends, searchValue, setSearchResult, t(mine_t));
    }, [searchValue]);

    return (<div className="">
      <input className="inputField" onChange={(e) => setSearchValue(e.target.value)} placeholder={"Name..."}/>
      {searchResult.map((element, index) => {
        return (
          <div className="autocomplete">
                    <span className="autocompleteText" key={"searchRID" + index} onClick={() => {
                      setCategory(element.split(" ")[0]);
                      setSearchResult([]);
                      fetchDataFromUrl(token, "rating?userID=" + element.split(";")[1] + "&locationID=" + location.idlocation, setRatingFetched, setErrorAndReset, setLoading);
                    }}>
                        {element.split(";")[0]}
                    </span>
          </div>);
      })}
    </div>);

  }

  function getHelperText(owner){
    if(showRatingEdit){
      return t(save_t).toLowerCase();
    }
    else if(owner){
      return t(rate_t);
    }
    else{
      return t(edit_location);
    }
  }

  function getPrice(){
    return <div className="price">
      {["€", "€", "€", "€"].map((value, i) => {
        if(i < location.price){
          return <label key={"price"+i}>{value}</label>;
        }
        else{
          return <label style={{color: "lightgray"}} key={"price"+i}>{value}</label>;
        }
      })
      }
    </div>;
  }

  function getCategory(){
    if(!location.category || location.category == "null"){
      return null;
    }
    return <div className="category">
      {locationCategories.filter((cat) => cat.value === location.category)[0].label}
    </div>;
  }

  return (
    <div>
      {/*Photo*/}
      <div>
        <div hidden={showPic === ""} className="picViewBackground" onClick={() => setShowPic("")}>
          <div style={{display: "inline-block", height: "100%", verticalAlign: "middle"}}/>
          <img src={BackendUrl + showPic} key={"imageBig"} alt={""} className="picView"/>
        </div>
        <Slide {...sliderImages.length > 1 ? properties : propertiesSingleImage} className="resizeSlideshow">
          {sliderImages.map((slideImage, i) => (
            <img className={imageOrientations[i] === "land" ? "resizeSlideshowImage" : "resizeSlideshowImagePor"}
                 src={getUrlForImage(slideImage, location.idlocation)} key={"image" + i} id={"image" + i} alt={""}
                 onClick={() => setShowPic(slideImage)}/>
          ))}
        </Slide>

        {/*Rating*/}
        <div className="ratingWheelLocation"
             onClick={() => showRatings ? setShowRatings(false) : setShowRatings(true)}>
          <RatingWheel classN="wheel" id={"wheel:" + location["idlocation"]} width={110}
                       thickness={0.17}
                       value={isNaN(ratingOverAll) ? 0 : parseInt(ratingOverAll)}/>
          <span className="nameLocationTopWheel">{t(click_for_details)}</span>
        </div>

        <h1>{location.name}</h1>
        {getPrice()}
        {owner ?
          <div className="shareWF">
            <span>{t(added_by)}</span>
            <span className="font-weight-bold"
                  onClick={() => {
                    setBuffer(owner);
                    setContent(9);
                  }}>{owner.firstname + " " + owner.lastname}</span>
          </div> :
          <div className="shareWF">
            <span>{location.sharewithfriends ?
              t(shared_with_friends) :
              t(not_shared_with_friends)}</span>

          </div>}
        <br/>
        {getCategory()}
        {/*      /!*Drei Buttons*!/*/}
        <div id="buttonsRoundLocation">
          <div id="button">
            <a href={location.phone ? "tel:" + location.phone : null}>
              <img className="iconSpaced bgLightBlue" src={phoneIcon}
                   style={location.phone ? null : {backgroundColor: "gray", opacity: 0.3}} alt={""}/>
            </a>
          </div>
          <div id="button">
            <a
              href={location.website ? location.website.toLocaleLowerCase().startsWith("https://") ? location.website.toLocaleLowerCase() : "https://" + location.website : null}
              target="_blank" rel="noreferrer">
              <img className="iconSpaced bgLightBlue" src={websiteIcon}
                   style={location.website ? null : {backgroundColor: "gray", opacity: 0.3}} alt={""}/>
            </a>
          </div>
          <div id="button" onClick={() => navigateTo(location.lon, location.lat)}>
            <img className="iconSpaced bgLightBlue" src={navigateIcon}
                 style={location.lon || location.lat ? null : {backgroundColor: "gray", opacity: 0.3}} alt={""}/>
          </div>
        </div>
        {showRatingEdit ? <div>
          <RatingEdit ratings={ratingsEdit} setRatings={setRatingsEdit} location={location}
                      ref={scrollToEditLabel}/>
          <br/><br/><br/><br/><br/>
        </div> : null}
        <div className="location-el" hidden={!showRatings || showRatingEdit}>
          <ToggleSwitch categories={categories} setCategory={setCategory} category={category}/>
          <div className="locationGrid">
            {!owner && category === t(friend_t) ? <Searchbar/> : null}
            {category !== t(friend_t) ? ratingsShown?.map((rating, index) => {
              return rating > 0 ? showRating(rating, index) : null;
            }) : null}
          </div>
        </div>
        {location && location.postal && location.city && location.number ?
          <div>
            <p>{location.street} {location.number}</p>
            <p>{location.postal}, {location.city}</p>
          </div>
          : null}
        {location.lon && location.lat ? <MiniMap curPos={[location.lat, location.lon]} setContent={setContent}/> : null}

        {/*      /!*Restliche form*!/*/}
        {location.phone ?
          <div>
            <div>
              <br/>
              <b>Phone</b>
            </div>
            {location.phone}
          </div> : null}

        {location.website ?
          <div>
            <div>
              <br/>
              <b>Website</b>
            </div>
            <a
              href={location.website ? location.website.toLocaleLowerCase().startsWith("https://") ? location.website.toLocaleLowerCase() : "https://" + location.website : null}
              target="_blank" rel="noreferrer">{location.website}</a>
          </div> : null}

        {location.ratings.itsratings.comment ?
          <div>
            <div>
              <br/>
              <b>{t(comment_t)}</b>
            </div>
            {location.ratings.itsratings.comment}
          </div> : null}
        <br/><br/><br/><br/>
      </div>
      {helpers ? <div>
        <img className="helperMiddleButtonIcon" src={helperIcon} alt={""}/>
        <label className="helper helperMiddleButton">{getHelperText(owner)}</label>
      </div> : null}
    </div>

  );

}