import './components.css';
import PropTypes from 'prop-types';

export const submit = ["submit", "speichern"];
export const latest_locations = ["LATEST LOCATIONS:", "LETZTE ORTE:"];
export const share_with_friends = ["share with friends", "mit Freunden teilen"];
export const shared_with_friends = ["shared with friends", "mit Freunden geteilt"];
export const not_shared_with_friends = ["not shared with friends", "nicht mit Freunden geteilt"];
export const click_for_details = ["click for details", "klicken für Details"];
export const has_been_at = ["has been at", "war hier"];
export const implemented_by = ["This app is implemented by", "Diese App wurde implementiert von"];
export const idea_t = ["Idea", "Idee"];
export const thanks = ["Thanks for using LoCaLIsA.", "Danke für die Benutzung von LoCaLIsA"];
export const tanks_text = ["In the future you will be able to add friends,\n" +
"            share your locations and explore unknown locations on the map.\n" +
"            Stay tuned!", "In Zukunft wird die App noch sehr viel mehr können..."];
export const no_location_added = ["has no location added", "hat keine Orte hinzugefügt"];
export const none_friend_has_locations = ["None of your friends has a location added", "Noch keiner deiner Freunde hat einen Ort hinzugefügt. "];
export const you_dont_have_a_location = ["You don't have any locations yet. Add your first location now.", "Du hast noch keine Orte hinzugefügt. Starte jetzt!"];
export const your_location_will_be_shown_here = ["Your locations will be shown here.", "Deine Orte werden hier angezeigt."];
export const add_rating_to_new_location_failed = ["Adding the rating to new location failed! \n Location is added" +
" without rating.", "Speicher der Bewertung fehlgeschlagen! Ort ohne Bewertung gespeichert."];
export const add_rating_to_location_failed = ["Upadating the rating of the location failed! \n Location is updated without" +
" new rating.", "Speichern der Bewertung fehlgeschlagen! Ort ohne neuer Bewertung gespeichert."];
export const add_new_location_failed = ["Adding new location failed!", "Ort hinzufügen fehlgeschlagen!"];
export const update_location_failed = ["Updating the location failed!", "Ort konnte nicht gespeichert werden!"];
export const name_of_location_missing = ["Name of location is missing!", "Kein Name angegeben!"];
export const no_address_current_position_used = ["No address specified, current location used.", "Keine Adresse angegeben, wir verwenden deine aktuelle Position."];
export const no_location_set_pin_is_used = ["No address specified, the set pin on the map is used.", "Keine Adresse angegeben, wir verwenden den gesetzten Pin auf der Karte."];
export const address_invalid = ["Address invalid, no geo data found!!", "Adresse ungültig, keine Korrdinaten gefunden."];
export const add_rating = ["Add rating", "Bewertung hinz."];
export const phone_number_t = ["Phone number", "Telefonnummer"];
export const website_t = ["Website", "Webseite"];
export const comment_t = ["Comment", "Kommentar"];
export const delete_location = ["Delete location", "Ort löschen"];
export const delete_t = ["Delete", "Löschen"];
export const abort_t = ["Abort", "Abbrechen"];
export const delete_user_m = ["Do you really want to delete your user?", "Den Benutzer wirklich löschen?"];
export const app_about = ["About this app", "Über diese App"];
export const concept_design = ["Concept + Design", "Konzept + Design"];
export const your_locations = ["Your locations", "Deine Orte"];
export const own_t = ["OWN", "MEINE"];
export const friends_t = ["FRIENDS", "FREUNDE"];
export const friend_t = ["FRIEND", "FREUND"];
export const both_t = ["BOTH", "BEIDES"];
export const upload_t = ["upload", "hochladen"];
export const your_connected_people = ["You'r connected people will be shown here.", "Deine Freunde werden hier angezeigt."];
export const you_dont_have_any_friends = ["You don't have any friends added yet. Add your friends now.", "Du hast noch keine Freunde hinzugefügt. Lade jetzt jemanden ein."];
export const search_friends = ["Search friend...", "Freunde suchen..."];
export const mine_t = ["MINE", "MEINE"];
export const owners_t = ["OWNER", "ORIGINAL"];
export const ambiance_t = ["Ambiance", "Umfeld"];
export const food_t = ["Food", "Essen"];
export const service_t = ["Service", "Bedienung"];
export const affordable_t = ["Price/Value", "Preis/Leistung"];
export const cleanness_t = ["Cleanness", "Sauberkeit"];
export const instagramability_t = ["Instagramability", "Instagramability"];
export const adventure_t = ["Adventure", "Abenteuer"];
export const overall_t = ["OVERALL", "ALLE"];
export const friends_more = ["Friends & more", "Deine Freunde"];
export const add_new_location = ["Add location", "Ort hinzufügen"];
export const edit_location = ["Edit location", "Ort bearbeiten"];
export const add_address = ["Add address", "Adresse hinz."];
export const search_new_friends = ["Search new Friend", "Freunde suchen"];
export const name_or_email = ["Name or Email", "Name oder Email"];
export const search_t = ["Search....", "Suchen..."];
export const enter_existing_name_email = ["Enter name or email of existing user.", "Nach Name oder Email im Suchfeld suchen."];
export const password_t = ["Password", "Passwort"];
export const old_password_t = ["Old password", "Altes Passwort"];
export const new_password_t = ["New password", "Neues Passwort"];
export const confirm_password_t = ["Confirm password", "Passwort bestätigen"];
export const dont_have_an_account = ["Don't have an account?", "Noch nicht angemeldet?"];
export const sign_up = ["Sign up", "Registrieren Sie sich"];
export const here_t = ["here", "hier"];
export const first_name = ["First Name", "Vorname"];
export const last_name = ["Last Name", "Nachname"];
export const your_email_is_not_valid = ["Your e-mail was not valid.", "Ungültige E-Mail."];
export const your_email_exists_already = ["Your e-mail already exists.", "E-Mail bereits registriert."];
export const you_have_already_an_account = ["You already have an account?", "Bereits registriert?"];
export const sign_in = ["Sign in", "Zum Login:"];
export const settings_t = ["Settings", "Einstellungen"];
export const language_t = ["Language:", "Sprache:"];
export const default_shown_locations = ["Default shown Locations:", "Standardwert für Orte:"];
export const default_shown_ratings = ["Default shown Ratings:", "Standardwert für Bewertungen:"];
export const hint_language = ["Hint: Refresh for updating language.", "Hinweis: Für Sprachänderung App neu laden."];
export const submit_your_rating = ["Submit your rating", "Bewertung hinz."];
export const germany_t = ["Germany", "Deutschland"];
export const austria_t = ["Austria", "Österreich"];
export const sweden_t = ["Sweden", "Schweden"];
export const italy_t = ["Italy", "Italien"];
export const france_t = ["France", "Frankreich"];
export const other_t = ["Other", "Anderer"];
export const all_t = ["Others", "Alle"];
export const lan_code = ["en", "de"];
export const country_t = ["Country", "Staat"];
export const zip_t = ["ZIP", "PLZ"];
export const city_t = ["City", "Ort"];
export const street_t = ["Street", "Straße"];
export const accept_t = ["Accept", "Annehmen"];
export const decline_t = ["Decline", "Ablehnen"];
export const continue_t = ["Continue", "Weitermachen"];
export const discard_t = ["Discard", "Verwerfen"];
export const added_by = ["added by ", "hinzugefügt von "];
export const accept_friend_1 = ["Do you want to accept", ""];
export const accept_friend_2 = ["request and add to your friends?", "Anfrage annehmen und zu Freunde hinzufügen?"];
export const discard_changes = ["Do you want to discard changes?", "Änderung verwerfen?"];
export const discard_GPS = ["GPS infos were used for the location, want to discard it and add address manually?", "GPS  informationen aus einem der Bilder wurden verwendet, diese verwerfen und Adresse eingeben?"];
export const discard_pin_m = ["A pin was set on the map, want to discard it and add address manually?", "Auf der" +
" Karte" +
" wurde ein Pin gesetzt, diesen verwerfen und Adresse eingeben?"];
export const not_yet_friends = [" has not yet confirmed your request, you only see email and location of confirmed friends.", " hat deine Anfrage noch nicht bestätigt, daher kannst du keine Orte oder E-Mail sehen."];
export const remove_friend = ["Remove friend", "Freund entfernen"];
export const cancel_t = ["CANCEL", "ABBRECHEN"];
export const save_t = ["SAVE", "SPEICHERN"];
export const new_people_to_connect = ["New people to connect:", "Neue Leute gefunden:"];
export const use_current_loc = ["use current location", "Standort verwenden"];
export const requests_t = ["Requests", "Anfragen"];
export const forgot_password = ["Forgot password", "Passwort vergessen"];
export const delete_user = ["Delete user and all data? \n Click here or just write an email", "Benutzer und" +
" alle Daten löschen? \n Einfach hier klicken oder eine E-Mail schreiben."];
export const search_for_loc = ["Search location name..", "Suche nach Name..."];
export const none_t = ["none", "ohne"];
export const friends_locations = ["Friends' locations", "Orte von Freunden"];
export const locations_t = ["locations", "Orte"];
export const close_to_me = ["close to me", "in der nähe"];
export const visted_by_you = ["Visited by you", "Von dir besucht"];
export const no_locations_found = ["Your filter setting do not match any locations. :(", "Deine Suche ergab keine Ergebnisse :("];
export const reset_filter = ["Reset Filter?", "Filter zurücksetzen?"];
export const show_city_in_newsfeed = ["Show City directly in newsfeed:", "Stadt von Orte im Newsfeed anzeigen:"];
export const share_location_std_opt = ["Standard option for new locations:", "Standartmäßig neue Orte teilen:"];
export const show_t = ["SHOW", "AN"];
export const hide_t = ["HIDE", "AUS"];
export const rate_t = ["Rate it too...", "Auch bewerten"];
export const share_t = ["SHARE", "TEILEN"];
export const dont_share = ["PRIVATE", "PRIVAT"];
export const discard_pin = ["Discard Pin", "Pin verwerfen"];
export const use_pin = ["Use pin", "Pin verwenden"];
export const use_gps_data = ["Use GPS data", "GPS verwenden"];
export const discard_gps_data = ["Drop GPS data", "GPS verwerfen"];
export const ignore_t = ["Ignore", "Ignorieren"];
export const delete_location_m = ["Really delete the location?", "Ort wirklich löschen?"];
export const use_gps_data_m = ["GPS Data attached to the image found, use this data as location?", "GPS Daten wurden" +
" im Bild gefunden, diese für den Ort übernehmen?"];
export const add_as_friend = ["Add as friend?", "Freund hinzufügen?"];
export const change_password = ["Change password", "Passwort ändern"];
export const adD_t = ["ADD", "HINZUFÜGEN"];
export const note_minimum_lengts = ["Note: Minimum length of first- and lastname is 2.\nEmail has to be" +
" valid.\n Minimum length of password is 4.", "Bemerke: Vor- und Nachname muss mindestens 2 Buchstaben haben, E-Mail" +
" muss gültig sein und das Passwort muss mindestens 4 Zeichen haben."];
export const email_verification_needed = ["Your Email address is not verified, remaining time: ", "Deine" +
" E-Mail-Adresse wurde noch nicht verifiziert, verbleibende Zeit: "];
export const hint_email_verify = ["If already done, login again.", "Wenn bereits erledigt, einfach neu einloggen."];
export const login_failed = ["Login failed:<br>", "Login fehlgeschlagen:<br>"];
export const invalid_token = ["Invalid token provided!", "Token ungültig!"];
export const email_or_password_wrong = ["Email or password incorrect.", "E-Mail oder Passwort falsch."];
export const email_verification_error = ["Email verification needed.", "E-Mail muss verifiziert werden."];
export const enter_email = ["Enter valid Email.", "Gültige E-Mail eingeben."];
export const sending_email_failed = ["Failed to send Email, please check again.", "Es konnte keine E-Mail gesendet" +
" werden, stimmt die E-Mail Adresse?"];
export const password_reset_email_sent = ["<br>We sent you an Email to rest your password.", "<br>Wir haben dir eine" +
" E-Mail zum Zurücksetzen deines Passworts gesendet."];
export const cookie_banner = ["This web-app uses cookies and services of others to enhance the user experience.", "Wir" +
" verwenden Cookies und Dienste Dritter um die Bedienung der App zu verbessern."];
export const cookie_banner_small = ["Only important cookies are used.", "Nur notwendige Cookies werden" +
" gespeichert."];
export const login_via_google = ["Accept cookies to login via Google.", "Cookies akzeptieren um via Google" +
" anzumelden."];
export const cookie_setting_warning = ["Setting are not applicable when cookies are deactivated.", "Die Einstellung" +
" sind leider nur verfügbar wenn Cookies akzeptiert wurden."];
export const passwords_do_not_match = ["Passwords do not match.", "Passwörter stimmen nicht überein."];
export const password_could_not_be_changed = ["Password could not be changed. Did you put in the right current" +
" password?", "Passwort konnte nicht geändert werden. Stimmt das aktuelle Passwort?"];
export const password_changed = ["Password changed successfully.", "Passwort wurde erfolgreich geändert."];
export const google_do_not_get_data = ["We do not forward any data to Google!", "Google erhält keine Daten von uns!"];
export const read_more = ["Read more", "Mehr dazu"];
export const close_t = ["Got it!", "Alles klar!"];
export const google_do_not_get_data_explaination = ["Sorry, we will translate this soon:" +
" Wenn du dich über Google anmeldest, kannst du einen" +
" bestehenden Google-Account nutzen um dich zu authentifizeren, das ist vor allem schnell und unkompliziert für Leute" +
" die bereits einen Google-Account haben. Nachdem du dich mit Google angemeldet hast bekommen wir (Localisa.app) für uns" +
" notwendigen Daten (Vorname, Nachname und E-Mail) von Google fälschungssicher übermittelt. Das ist schon alles was" +
" an Datenaustausch mit Google passiert." +
" Wir seneden zu keinen Zeitpunkt private Daten oder Informationen die du uns anvertraust an Google oder andere" +
" Dritte weiter.", "Wenn du dich über Google anmeldest, kannst du einen" +
" bestehenden Google-Account nutzen um dich zu authentifizeren, das ist vor allem schnell und unkompliziert für Leute" +
" die bereits einen Google-Account haben. Nachdem du dich mit Google angemeldet hast bekommen wir (Localisa.app) für uns" +
" notwendigen Daten (Vorname, Nachname und E-Mail) von Google fälschungssicher übermittelt. Das ist schon alles was" +
" an Datenaustausch mit Google passiert." +
" Wir senden zu keinen Zeitpunkt private Daten oder Informationen die du uns anvertraust an Google oder andere" +
" Dritte weiter."];
//-------------------------helpers-------------------------
export const add_t = ["add", "hinzufügen"];
export const logout_t = ["logout", "ausloggen"];
export const choose_img = ["Choose image", "Bild auswählen"];
export const category_t = ["Category", "Kategorie"];
export const parking_t = ["Parking", "Parkplatz"];
export const sight_t = ["Sight", "Sehenswürdigkeit"];
export const flight_t = ["Flight place", "Flugplatz"];
export const bay_t = ["Bay", "Bucht"];
export const anchor_t = ["Anchor place", "Ankerplatz"];
export const bouy_t = ["Moored buoy", "Verankerte Boje"];
export const hiking_t = ["Hiking-Trail", "Wanderweg"];
export const view_t = ["Viewpoint", "Aussichtspunkt"];
export const shop_t = ["Shop", "Laden"];
export const cafe_t = ["Coffee", "Café"];
export const activity_t = ["Leisure activity", "Freizeitaktivität"];
export const bar_t = ["Bar", "Bar"];
export const club_t = ["Club", "Club"];

export function t(text){
  if(localStorage.getItem("language") === "EN"){
    return text[0];
  }
  else{
    return text[1];
  }

}

t.propTypes = {
  token: PropTypes.string.isRequired,
  setPin: PropTypes.string,
  submit: PropTypes.isRequired,
  setContent: PropTypes.isRequired
};
