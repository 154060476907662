import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';

import RatingEdit from "./RatingEdit";
import UploadImages from "./myPictureInput";

import './components.css';
import {
  buildOverpassAPI,
  esc, getUrlForImage, locationCategories,
  MiniMap,
  ratingToString,
  showModal
} from "./Utils";
import {
  abort_t,
  add_address,
  add_new_location,
  add_rating,
  address_invalid,
  austria_t,
  category_t,
  city_t,
  comment_t,
  country_t,
  delete_location,
  delete_location_m,
  delete_t,
  discard_changes,
  discard_GPS,
  discard_gps_data,
  discard_pin,
  discard_pin_m,
  edit_location,
  france_t,
  germany_t,
  ignore_t,
  italy_t,
  lan_code,
  no_address_current_position_used,
  no_location_set_pin_is_used,
  other_t,
  phone_number_t,
  share_t,
  share_with_friends,
  street_t,
  sweden_t,
  t,
  use_current_loc,
  use_gps_data,
  use_gps_data_m,
  use_pin,
  website_t,
  zip_t
} from "./languages";
import standortPin from "../assets/mapPinNew.png";
import Select from 'react-select';
import {fetchDataFromExtUrl, fetchLocalisa, postDataToUrl} from "./API";
import {ESMContext, LoadingContext, LocalisaContext} from "../App";
import {ContentContext} from "./ContentScreen";

function showSuggestions(listCountry, setListCountry, setCountry, className){
  return <div>
    {listCountry.map((element, index) => {
      return (<div className={"autocomplete " + className}>
                    <span className="autocompleteText" key={"searchRID" + index} onClick={() => {
                      setListCountry([]);
                      if(element !== t(other_t)){
                        setCountry(element);
                      }
                    }}>
                        {element}
                    </span>
      </div>);
    })}
  </div>;
}

export default function Add({submit, locationGiven, setWarningRef, curPos, pin}){

  const [isAddress, setIsAddress] = useState(locationGiven ? locationGiven.street ? 1 : 0 : 0);
  const [isRating, setIsRating] = useState(locationGiven ? Number(locationGiven.ratings.myratings.ratings) > 0 ? 1 : 0 : 0);
  const [addressInvalid, setAddressInvalid] = useState(1);
  const [usePin, setUsePin] = useState(1);
  const [useGPSImg, setUseGPSImg] = useState(0);

  const [imageGPSInfo, setImageGPSInfo] = useState([]);
  const [images, setImages] = useState([]);
  const given_images =locationGiven ? locationGiven.photo.split(";") : [];
  //Todo: locationGiven.comment ist nun locationGiven.ratings.myratings.comment
  const [location, setLocation] = useState(locationGiven ? {...locationGiven, ratings: new Map(), comment: locationGiven?.ratings?.myratings?.comment} : {
    name: pin && pin[2] ? pin[2].name : "",
    street: "",
    number: "",
    postal: "",
    city: "",
    country: "",
    website: pin && pin[2] ? pin[2].website ? pin[2].website : null : "",
    comment: pin && pin[2] ? pin[2]?.wheelchair && pin[2].wheelchair === "yes" ? "Barrierefrei" : null : "",
    phone: pin && pin[2] ? pin[2].phone ? pin[2].phone : null : "",
    opening: "",
    ratings: new Map(),
    sharewithfriends: localStorage.getItem("shareLocStdOpt") === t(share_t) ? 1 : 0,
    lon: pin ? pin[1] : curPos[1],
    lat: pin ? pin[0] : curPos[0],
    price: 0,
    category: pin && pin[2] ? pin[2].category ? pin[2].category : null : ""
  });
  const [cityAuto, setCityAuto] = useState("");
  const [listCity, setListCity] = useState([]);
  const [listCountry, setListCountry] = useState(locationGiven && locationGiven.country ? [] : [t(germany_t), t(austria_t), t(sweden_t), t(italy_t), t(france_t), t(other_t)]);
  const [listPostal, setListPostal] = useState([]);
  const [listStreet, setListStreet] = useState([]);
  const [listName, setListName] = useState([]);

  const [showWarningSaveChanges, setShowWarningSaveChanges] = useState(0);
  const [showWarningPin, setShowWarningPin] = useState(0);
  const [showGPSfound, setShowGPSfound] = useState(0);
  const [deleteLocationWarn, setDeleteLocationWarn] = useState(0);

  const inputStreet = useRef(null);
  const invalidAddressWarn = useRef(null);
  const noNameWarn = useRef(null);

  const token = useContext(LocalisaContext);
  const setContent = useContext(ContentContext)
  const setLoading = useContext(LoadingContext)
  const setError = useContext(ESMContext)

  function setIsUpdating(isLoading){
    setLoading(-isLoading)
  }

  function handleCityForCoords(element){
    if(element.address.city){
      setCityAuto(element.address.city);
    }
    else if(element.address.town){
      setCityAuto(element.address.town);
    }
    else if(element.address.village){
      setCityAuto(element.address.village);
    }
    else if(element.address.municipality){
      setCityAuto(element.address.municipality);
    }
    else if(element.address.county){
      setCityAuto(element.address.county);
    }
  }

  function updateLocationFields(fields, value){
    let tmp = {...location};
    fields.map((field, index) => {
      return tmp[field] = value[index];
    });
    tmp["updated"] = "true";
    setLocation(tmp);
  }

  const saveLocation = useCallback(() => {
    if(isAddress && addressInvalid){
      invalidAddressWarn.current.scrollIntoView(false);
      return;
    }
    if(location.name.length < 1){
      noNameWarn.current.scrollIntoView(true);
      setError("name of location missing");
      return;
    }

    let url = 'locations';
    let ratingsArray = ratingToString(location.ratings);
    const data = new FormData();

    if(locationGiven){
      url = url + "/" + locationGiven.idlocation + "/edit";
    }
    data.append("photo", images.join(";"));
    data.append("name", esc(location.name));
    if(isAddress && !addressInvalid){
      data.append("street", esc(location.street));
      data.append("number", esc(location.number));
      data.append("postal", esc(location.postal));
      data.append("country", esc(location.country));
    }
    else{
      data.append("street", "");
      data.append("number", "");
      data.append("postal", "");
      data.append("country", "");
    }
    if(location.city.length && isAddress){
      data.append("city", esc(location.city));
    }
    else{
      data.append("city", esc(cityAuto));
    }
    data.append("phone", esc(location.phone));
    data.append("website", esc(location.website));
    data.append("comment", esc(location.comment));
    data.append("ratings", ratingsArray);
    data.append("lat", "" + location.lat);
    data.append("lon", "" + location.lon);
    data.append("category", location.category);
    data.append("price", "" + location.price);
    data.append("opening", esc(location.opening));
    data.append("sharewithfriends", location.sharewithfriends ? "true" : "false" );

    postDataToUrl(token, url, data, ()=>setContent(102), setError, setLoading);

  }, [addressInvalid, location, cityAuto, images, isAddress, locationGiven, setContent]);

  function handleUpdateCoordinates(response){
    updateLocationFields(["lat", "lon"], [response[0]["lat"], response[0]["lon"]]);
    setAddressInvalid(0);
  }

  function updateCoordinatesForAddress(){
    const inp = location.street + "+" + location.number + "+" + location.postal + "+" + location.city;
    const url = "https://nominatim.openstreetmap.org/search?format=json&limit=3&q=" + inp;
    fetchDataFromExtUrl(url, handleUpdateCoordinates, () => {
      setAddressInvalid(1);
    }, setIsUpdating);
  }

  useEffect(() => {
    if(location.lat !== 0 && location.lon !== 0 && location.name.length > 2){
      const offset = 0.01; //0.002 ~= 200m
      const timer = setTimeout(() => {
        fetch(buildOverpassAPI("amenity=restaurant", (location.lat - offset) + ',' + (location.lon - offset) + ',' + (location.lat + offset) + ',' + (location.lon + offset)))
          .then((e) => {
            e.text().then((e) => {
              let XMLParser = require('react-xml-parser');
              let data = new XMLParser().parseFromString(e);
              let locs = [];
              setIsUpdating(0);
              data.children.map((loc) => {
                let autofill = false;
                if(!loc.name || loc.name !== "node"){
                  return null;
                }

                loc.children.map((att) => {
                  if(att.attributes.k === "name" && att.attributes.v.toLowerCase().includes(location.name.toLowerCase()) && att.attributes.v.toLowerCase() !== location.name.toLowerCase()){
                    locs = [att.attributes.v, ...locs];
                  }
                  if(att.attributes.k === "name" && att.attributes.v.toLowerCase() === location.name.toLowerCase()){
                    autofill = true;
                  }
                });

                if(autofill){
                  let website_ = "";
                  let phone_ = "";
                  let comment_ = "";
                  loc.children.map((att) => {
                    if(att.attributes.k.includes("website") && !location.website.length){
                      website_ = att.attributes.v;
                    }
                    if(att.attributes.k.includes("phone") && !location.phone.length){
                      phone_ = att.attributes.v;
                    }
                    if(att.attributes.k === "wheelchair" && !location.comment.length){
                      comment_ = att.attributes.v === "yes" ? "Barrierefrei" : null;
                    }
                    updateLocationFields(["website", "phone", "comment", "category", "lon", "lat"], [website_, phone_, comment_, "restaurant", loc.attributes.lon, loc.attributes.lat]);
                  });
                }
              });
              setListName(locs);
            });
          });

      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [location.name]);

  useEffect(() => {
    if(!isAddress && location.lat && location.lon){
      const url = "https://nominatim.openstreetmap.org/reverse?format=json&lat=" + location.lat + "&lon=" + location.lon + "&zoom=18&addressdetails=1";
      fetchDataFromExtUrl(url, handleCityForCoords, () => setCityAuto(""));
    }
    else{
      setCityAuto(location.city);
    }
  }, [location.lat, location.lon, isAddress, location.city]);

  function setCoordByGPS(){
    const lat = parseFloat(imageGPSInfo.GPSLatitude.split(",")[0])
      + parseFloat(imageGPSInfo.GPSLatitude.split(",")[1]) / 60
      + parseFloat(imageGPSInfo.GPSLatitude.split(",")[2]) / 3600;
    const lon = parseFloat(imageGPSInfo.GPSLongitude.split(",")[0])
      + parseFloat(imageGPSInfo.GPSLongitude.split(",")[1]) / 60
      + parseFloat(imageGPSInfo.GPSLongitude.split(",")[2]) / 3600;
    console.log(imageGPSInfo.GPSLatitude);
    console.log(lat);
    console.log(imageGPSInfo.GPSLongitude);
    console.log(lon);
    updateLocationFields(["lat", "lon"], [lat, lon]);
    setShowGPSfound(0);
    setUseGPSImg(1);
    setIsAddress(0);
  }

  useEffect(() => {
    if(imageGPSInfo.GPSLatitude){
      setShowGPSfound(1);
    }
  }, [imageGPSInfo]);

  function deleteLocation(){
    let url = 'locations?locationID=' + locationGiven.idlocation;
    //todo cat=setError and close Modal?
    fetchLocalisa(token, url, () => setContent(103), () => setContent(103), null, setLoading, "DELETE");
  }

  useEffect(() => {
    setWarningRef.current = setContent;
    submit.current = saveLocation;
  });

  useEffect(() => {
    if(location.updated){
      setWarningRef.current = setShowWarningSaveChanges;
    }
    if(location.name.length){
      setError(null);
    }
  }, [location, setWarningRef]);

  useEffect(() => {
    if(location.street.length > 2 && location.number.length && location.postal.length > 2 && location.city.length > 2){
      const timer = setTimeout(() => {
        updateCoordinatesForAddress();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [location.street, location.number, location.city, location.postal]);

  function filterList(list){
    return list.filter((element, i, arr) => {
      if(i === 0){
        return true;
      }
      else{
        return element !== arr[i - 1];
      }
    });
  }

  useEffect(() => {
    if(location.street.length < 3 || locationGiven){
      setListPostal([]);
      setListCity([]);
      setListPostal([]);
      return;
    }
    if(location.city.length){
      setListPostal([]);
      setListCity([]);
    }
    const url = "https://nominatim.openstreetmap.org/search?" +
      "format=json&limit=10" +
      "&addressdetails=1" +
      "&accept-language=" + t(lan_code) +
      (location.postal.length ? "&postalcode=" + location.postal : "") +
      (location.country.length ? "&country=" + location.country : "") +
      (location.street.length ? "&street=" + location.street : "") +
      (location.city.length ? "&city=" + location.city : "");

    let listCity = [];
    let listStreet = [];
    setListStreet(["..."]);

    function handleUpdateStreet(response){
      response.map((element) => {
        if(element.address.road){
          listStreet = [...listStreet, element.address.road];
        }
        if(element.address.city){
          listCity = [...listCity, element.address.city];
        }
        else if(element.address.town){
          listCity = [...listCity, element.address.town];
        }
        else if(element.address.village){
          listCity = [...listCity, element.address.village];
        }
        else if(element.address.municipality){
          listCity = [...listCity, element.address.municipality];
        }
        else if(element.address.county){
          listCity = [...listCity, element.address.county];
        }
        return null;
      });
      listStreet = filterList(listStreet);
      listCity = filterList(listCity);
      if(listCity.length && !location.city.length){
        setListCity(listCity);
      }
      if(listStreet.length){
        setListStreet(listStreet);
      }
      else{
        setListStreet([]);
      }
      if(listPostal.length + listCity.length > 5){
        inputStreet.current.scrollIntoView(false);
      }
    }

    const timer = setTimeout(() => {
      fetchDataFromExtUrl(url, handleUpdateStreet);
    }, 1000);
    return () => clearTimeout(timer);
  }, [location.street]);

  useEffect(() => {
    if(location.city.length < 3 || location.postal.length){
      setListPostal([]);
      return;
    }
    const url = "https://nominatim.openstreetmap.org/search?" +
      "format=json&limit=10" +
      "&addressdetails=1" +
      "&accept-language=" + t(lan_code) +
      (location.city.length ? "&q=" + location.city : "");

    let listPostalV = [];
    setListPostal(["..."]);

    function handleUpdateCity(response){
      response.map((element) => {
        if((element.address.city && element.address.city.toLowerCase().includes(location.city.toLowerCase())) ||
          (element.address.town && element.address.town.toLowerCase().includes(location.city.toLowerCase())) ||
          (element.address.village && element.address.village.toLowerCase().includes(location.city.toLowerCase())) ||
          (element.address.municipality && element.address.municipality.toLowerCase().includes(location.city.toLowerCase())) ||
          (element.address.county && element.address.county.toLowerCase().includes(location.city.toLowerCase()))
        ){
          if(element.address.postcode){
            if(element.address.country && element.address.country.toLowerCase().includes(location.country.toLowerCase())){
              listPostalV = [...listPostalV, element.address.postcode];
            }
          }
        }
        return null;
      });
      listPostalV = filterList(listPostalV);
      if(listPostalV.length){
        setListPostal(listPostalV);
      }
      else{
        setListPostal([]);
      }
    }

    const timer = setTimeout(() => {
      fetchDataFromExtUrl(url, handleUpdateCity);
    }, 1000);
    return () => clearTimeout(timer);
  }, [location.city]);

  useEffect(() => {
    if(location.country.length < 5 || location.postal.length < 3 || location.city.length){
      setListCity([]);
      return;
    }
    const url = "https://nominatim.openstreetmap.org/search?" +
      "format=json&limit=10" +
      "&addressdetails=1" +
      "&accept-language=" + t(lan_code) +
      (location.country.length ? "&country=" + location.country : "") +
      (location.postal.length ? "&postalcode=" + location.postal : "");
    let listCityV = [];
    setListCity(["..."]);

    function handleUpdatePostal(response){
      response.map((element) => {
        if(element.address.city){
          listCityV = [...listCityV, element.address.city];
        }
        else if(element.address.town){
          listCityV = [...listCityV, element.address.town];
        }
        else if(element.address.village){
          listCityV = [...listCityV, element.address.village];
        }
        else if(element.address.municipality){
          listCityV = [...listCityV, element.address.municipality];
        }
        else if(element.address.county){
          listCityV = [...listCityV, element.address.county];
        }
        return null;
      });
      listCityV = filterList(listCityV);
      if(listCityV.length){
        setListCity(listCityV);
      }
      else{
        setListCity([]);
      }
    }

    const timer = setTimeout(() => {
      fetchDataFromExtUrl(url, handleUpdatePostal);
    }, 1000);

    return () => clearTimeout(timer);
  }, [location.postal]);

  function updateLocation(field, value){
    updateLocationFields([field], [value]);
  }

  function enableAddress(){
    if((pin && usePin) || useGPSImg){
      setShowWarningPin(1);
    }
    else{
      setIsAddress(1);
      if(location.street.length > 2){
        updateCoordinatesForAddress();
      }
    }
  }

  function addressInput(){
    return <div>
      {curPos[0] && curPos[1] ?
        <button className="buttonSq" onClick={() => {
          setIsAddress(0);
          updateLocationFields(["lat", "lon"], [curPos[0], curPos[1]]);
        }}>
          {t(use_current_loc)}
        </button> : null}
      <br/>

      <input value={location.country} className="inputField country" placeholder={t(country_t)}
             onChange={e => {
               updateLocation("country", e.target.value);
               setListCountry([]);
             }}/>
      {showSuggestions(listCountry, setListCountry, (val) => updateLocation("country", val), "country countryPos")}
      <br/>

      <input value={location.postal} className="inputField code" placeholder={t(zip_t)} type="number"
             onChange={e => {
               updateLocation("postal", e.target.value);
               setListPostal([]);
             }}/>

      <input value={location.city} className="inputField city" placeholder={t(city_t)}
             onChange={e => {
               updateLocation("city", e.target.value);
             }}/>
      {showSuggestions(listPostal, setListPostal, (val) => updateLocation("postal", val), "country countryPos")}
      {showSuggestions(listCity, setListCity, (val) => updateLocation("city", val), "country countryPos")}
      <br/>

      <input value={location.street} className="inputField str" placeholder={t(street_t)}
             onChange={e => updateLocation("street", e.target.value)}/>
      <input value={location.number} className="inputField nr" placeholder="Nr"
             onChange={e => updateLocation("number", e.target.value)}/>
      {showSuggestions(listStreet, setListStreet, (val) => updateLocation("street", val), "countryPos")}
      <br/>

      {addressInvalid ? <p className="alert">{t(address_invalid)}</p> : null}

    </div>;
  }

  function showPrice(){
    return <div className="price">
      {["-", "€", "€", "€", "€"].map((value, i) => {
        if(i < location.price + 1){
          return <label key={"label"+i} onClick={() => updateLocation("price", i)}>
            {value}
          </label>;
        }
        else{
          return <label key={"label"+i} onClick={() => updateLocation("price", i)} style={{color: "lightgray"}}>
            {value}
          </label>;
        }
      })
      }
    </div>;
  }

  return (
    <div>
      {showWarningSaveChanges ? showModal(setShowWarningSaveChanges, () => {
        setContent(showWarningSaveChanges);
      }, t(discard_changes)) : null}
      {showWarningPin && !useGPSImg ? showModal(setShowWarningPin, () => {
        setIsAddress(1);
        if(location.street.length > 2){
          updateCoordinatesForAddress();
        }
        setShowWarningPin(0);
        setUsePin(0);
      }, t(discard_pin_m), t(use_pin), t(discard_pin)) : null}
      {showWarningPin && useGPSImg ? showModal(setShowWarningPin, () => {
        setIsAddress(1);
        if(location.street.length > 2){
          updateCoordinatesForAddress();
        }
        setShowWarningPin(0);
        setUsePin(0);
      }, t(discard_GPS), t(use_gps_data), t(discard_gps_data)) : null}
      {showGPSfound ? showModal(null, () => {
        setShowGPSfound(0);
      }, t(use_gps_data_m), t(use_gps_data), t(ignore_t), setCoordByGPS) : null}
      {deleteLocationWarn ? showModal(null, () => {
        setDeleteLocationWarn(0);
      }, t(delete_location_m), t(delete_t), t(abort_t), deleteLocation) : null}

      {locationGiven ? <h1 ref={noNameWarn}>{t(edit_location)}</h1> : <h1 ref={noNameWarn}>{t(add_new_location)}</h1>}

      <UploadImages givenImages={given_images.map((img)=>getUrlForImage(img, location.idlocation))} setGivenImages={setImages} setGPSInfo={setImageGPSInfo}/>
      <br/>

      <p className="errorShown alert" id={"errorFieldAdd"}/>
      <br/>

      <input value={location.name} className="input" placeholder="Name"
             onChange={e => updateLocation("name", e.target.value)}/>
      {showSuggestions(listName, setListName, (val) => {
        updateLocation("name", val);
        setIsUpdating(1);
      }, "name namePos")}
      <br/>
      <br/>
      <div className="shareWF">
        <span>{t(share_with_friends)}</span>
        <input className="checkbox" type="radio" checked={!!location.sharewithfriends}
               onClick={() => updateLocation("sharewithfriends", location.sharewithfriends ? 0 : 1)}
               readOnly/>
      </div>
      {showPrice()}
      <br/>
      <br/>
      {!isAddress && !useGPSImg ?
        (!pin || !usePin ?
          (<p id="locationAlert">{t(no_address_current_position_used)}</p>) :
          (<p className="locationAlert">{t(no_location_set_pin_is_used)}<br/></p>)) :
        null}

      {location.lon && location.lat ?
        <div><MiniMap curPos={[location.lat, location.lon]}/>
          {cityAuto ?
            <span className="autoCity">
              <img style={{height: 7}} src={standortPin} alt={""}/>
              {cityAuto}
            </span> :
            null}
        </div> :
        null}
      <br/>
      <div className="selectCat">
        <Select options={locationCategories} isClearable placeholder={t(category_t)} id="selectCat"
                onChange={(e) => updateLocation("category", e ? e.value : "")} defaultInputValue={location.category}/>
      </div>
      <br/>
      <button className="buttonSq" onClick={enableAddress} hidden={!!isAddress}>
        {t(add_address)}
      </button>

      {isAddress ? addressInput() : null}
      <br ref={inputStreet}/>

      {isRating ? <br/> : <button className="buttonSq" onClick={() => setIsRating(1)}>{t(add_rating)}</button>}
      {isRating ? <RatingEdit ratings={location.ratings} setRatings={e => updateLocation("ratings", e)}
                              location={locationGiven}/> : null}

      <br/>
      <br ref={invalidAddressWarn}/>
      <input value={location.phone} className="inputField" placeholder={t(phone_number_t)} type="tel"
             onChange={e => updateLocation("phone", e.target.value)}/>
      <br/>
      <input value={location.website} className="inputField" placeholder={t(website_t)}
             onChange={e => updateLocation("website", e.target.value)}/>
      <br/>
      <textarea value={location.comment} className="inputField commentField" rows="4" placeholder={t(comment_t)}
                onChange={e => updateLocation("comment", e.target.value)}/>

      <br/>
      {locationGiven ?
        <button className="buttonSq bgRed"
                onClick={() => setDeleteLocationWarn(1)}>{t(delete_location)}</button> : null}
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>);

}