import * as React from 'react';
import {useContext} from "react";
import PropTypes from 'prop-types';

import './components.css';
import profilIcon from '../assets/profilIcon.png';
import mapIcon from '../assets/mapIcon.png';
import newsIcon from '../assets/newsIcon.png';
import impressumIcon from '../assets/impressumIcon.png';
import {ContentContext} from "./ContentScreen";

export default function Menu({middleFunction, middleIcon, menuSelection1}){

  const menuSelection0 = useContext(ContentContext)
  const menuSelection = menuSelection1 ? menuSelection1 : menuSelection0

  return (
    <div id="menu" className="unselectable">
      <div className="nav">
        <img className="icon" src={profilIcon} onClick={() => menuSelection(1)} alt={"icon"}/>
      </div>
      <div className="nav">
        <img className="icon" src={mapIcon} onClick={() => menuSelection(2)} alt={"icon"}/>
      </div>
      <div>
        <img id="main" className="icon" src={middleIcon} onClick={() => middleFunction()} alt={"icon"}/>
      </div>
      <div className="nav">
        <img className="icon" src={newsIcon} onClick={() => menuSelection(3)} alt={"icon"}/>
      </div>
      <div className="nav">
        <img className="icon" src={impressumIcon} onClick={() => menuSelection(4)} alt={"icon"}/>
      </div>
    </div>
  );
}

Menu.propTypes = {
  middleFunction: PropTypes.func.isRequired
  //add proptype for middleIcon here
};
