import * as React from 'react';
import {useContext, useEffect, useState} from "react";

import './components.css';
import plusImage from "../assets/middlePlus.png";
import {
  searchInPersons,
  showLocationsWithRating,
  ToggleSwitch,
  ToggleSwitchLabels
} from "./Utils";
import {
  all_t,
  city_t,
  close_to_me,
  friends_locations,
  lan_code,
  locations_t,
  none_friend_has_locations,
  none_t,
  search_t,
  t,
  use_current_loc, visted_by_you,
  you_dont_have_a_location,
  your_location_will_be_shown_here,
  your_locations
} from "./languages";
import helperIcon from "../assets/helperIcon.png";
import {fetchDataFromExtUrl} from "./API";
import {ContentContext} from "./ContentScreen";

function showNoLocationAvailable({setContent}){
  return (<div className="location-el" id="signup">
    <p className="infoNew">{t(your_location_will_be_shown_here)}</p>
    <div className="button">
      <img className="img" src={plusImage} onClick={() => setContent(5)} alt={""}/>
    </div>
    <p id="add" className="infoNew">{t(you_dont_have_a_location)}</p>
  </div>);
}

function measure(lat1, lon1, lat2, lon2){
  let R = 6378.137;
  let dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
  let dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
  let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

export default function Newsfeed({data, setLocation, filterRef}){

  const [locationsFriendsVisitedFiltered, setLocationsFriendsVisitedFiltered] = useState(data.locations_friends);
  const [isFilter, setIsFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Radius");
  const [radius, setRadius] = useState(0);
  const [person, setPerson] = useState(t(all_t));
  const [city, setCity] = useState(t(all_t));
  const [personID, setPersonID] = useState(0);
  const [coord, setCoord] = useState([0, 0]);
  const helpers = localStorage.getItem("helpers");
  const filters = ["Radius", "Person", t(city_t)];
  const setContent = useContext(ContentContext)

  useEffect(() => {
    setLocationsFriendsVisitedFiltered(data.locations_friends.filter((loc) => filterLocations(loc)).filter((loc) => !isNaN(loc.ratings.myratings?.ratings) && parseInt(loc.ratings.myratings.ratings)));
  }, [radius, city, personID, data]);

  function Searchbar(){
    const [searchValue, setSearchValue] = useState("");
    const [searchResult, setSearchResult] = useState([]);

    function setSearchResult_(result){
      setSearchResult([...result, {"display_name": t(use_current_loc)}, {"display_name": t(all_t)}]);
    }

    useEffect(() => {
      if(selectedFilter === "Person"){
        searchInPersons(data.friends, searchValue, setSearchResult, t(all_t));
      }
      else{
        setSearchResult([{"display_name": t(use_current_loc)}, {"display_name": t(all_t)}]);
        if(searchValue.length > 1){
          setSearchResult([{"display_name": "..."}, {"display_name": t(use_current_loc)}, {"display_name": t(all_t)}]);
          const timer = setTimeout(() => {
            const url = "https://nominatim.openstreetmap.org/search?format=json&limit=10&accept-language=" + t(lan_code) + "&q=" + searchValue;
            fetchDataFromExtUrl(url, setSearchResult_);
          }, 1000);
          return () => clearTimeout(timer);
        }
      }
    }, [searchValue]);

    return (<div className="">
      <input className="inputField" onChange={(e) => setSearchValue(e.target.value)} placeholder={"Name..."}/>
      {searchResult.map((element, index) => {
        return (
          <div className="autocomplete">
                    <span className="autocompleteText" key={"searchRID" + index} onClick={() => {
                      if(selectedFilter === "Person"){
                        setPersonID(element.split(";")[1] ? element.split(";")[1] : 0);
                        setPerson(element.split(" ")[0]);
                      }
                      else{
                        setCity(element.display_name.split(",")[0].split(" ")[0]);
                        if(element.display_name === t(use_current_loc)){
                          setCoord([data.curPos[0], data.curPos[1]]);
                        }
                        else{
                          setCoord([element.lat, element.lon]);
                        }
                      }

                      setSearchResult([]);
                    }}>
                        {selectedFilter === "Person" ? element.split(";")[0] : element.display_name}
                    </span>
          </div>);
      })}
    </div>);

  }

  function displayFilterSettings(){
    const labels = [
      radius > 1 ? radius + " km" : t(none_t),
      person === t(all_t) ? t(all_t) : person,
      city === t(all_t) ? t(all_t) : city
    ];

    return (
      <div>
        <ToggleSwitchLabels categories={labels} setCategory={setSelectedFilter} category={selectedFilter}
                            className="labelForToggleSwitch" categoriesDefault={[t(none_t), t(all_t), t(all_t)]}/>
        <ToggleSwitch categories={filters} setCategory={setSelectedFilter} category={selectedFilter}/>

        {selectedFilter === "Person" || selectedFilter === t(city_t) ?
          <Searchbar/> :
          <input type="range" min="0" max="200" value={radius} className="slider" id="radiusSlider"
                 onChange={(e) => setRadius(e.target.value)}/>}
        <br/>

        <button className="buttonSq" onClick={() => {
          setCoord([data.curPos[0], data.curPos[1]]);
          setRadius(10);
          setCity(t(use_current_loc).split(",")[0].split(" ")[0]);
        }}>
          {t(close_to_me)}
        </button>
      </div>);
  }

  useEffect(() => {
    filterRef.current = () => {
      setIsFilter((value) => (!value));
      if(!isFilter){
        window.scrollTo(0, 0);
      }
    };
  });

  function onLocClickFunction(location){
    setLocation(location);
    setContent(6);
  }

  function filterLocations(loc){
    let res = true;
    if(personID){
      res = parseInt(loc.user) === parseInt("" + personID);
    }
    if(city !== t(all_t) && res){
      if(radius > 1){
        res = measure(coord[0], coord[1], loc.lat, loc.lon) < radius;
      }
      else{
        res = measure(coord[0], coord[1], loc.lat, loc.lon) < 10;
      }
    }
    return res;
  }

  function resetFilter(){
    setRadius(0);
    setPerson(t(all_t));
    setCity(t(all_t));
    setPersonID(0);
    setCoord([0, 0]);
  }

  return (<div>
    <h1>{"Newsfeed"}</h1>
    {isFilter ? displayFilterSettings() : null}

    {/*-----------MY LOCATIONS-----------*/}
    {person === t(all_t) ? <h2>{t(your_locations)}</h2> : null}
    {person === t(all_t) ?
      data.my_locations.length === 0 ? showNoLocationAvailable(setContent) : showLocationsWithRating(onLocClickFunction, data.my_locations.filter((loc) => filterLocations(loc))) :
      null
    }

    {/*-----------FRIENDS-LOCATIONS VISTED-----------*/}
    {locationsFriendsVisitedFiltered.length ? <h2>{t(visted_by_you)}</h2> : null}
    {locationsFriendsVisitedFiltered.length ?
      showLocationsWithRating(onLocClickFunction, locationsFriendsVisitedFiltered, data.friends, null, true) :
      null}
    {locationsFriendsVisitedFiltered.length ? <hr/> : null}

    {/*-----------FRIENDS-LOCATIONS-----------*/}
    <h2>{person === t(all_t) ? t(friends_locations) : person + "s " + t(locations_t)}</h2>
    {data.locations_friends.length === 0 ?
      <p className="location-el">{t(none_friend_has_locations)}{":("}</p> :
      showLocationsWithRating(onLocClickFunction, data.locations_friends.filter((loc) => filterLocations(loc)), data.friends, resetFilter)}

    {helpers && !isFilter ? <div>
      <img className="helperMiddleButtonIcon" src={helperIcon} alt={""}/>
      <label className="helper helperMiddleButton">{t(search_t)}</label>
    </div> : null}
  </div>);

}

