import React, {useEffect, useState} from 'react';

import './components.css';
import Knob from "../assets/knob";
import PropTypes from 'prop-types';
import {
  adventure_t,
  affordable_t,
  ambiance_t,
  food_t,
  instagramability_t,
  service_t,
  t,
  cleanness_t
} from "./languages";

export const ratingsName = [t(ambiance_t), t(food_t), t(affordable_t), t(service_t), t(cleanness_t), t(instagramability_t), t(adventure_t)]; //must not contain double values!

function getColor(value){ //just used to set fg color for initalise wheel because no canvas update called
  if(value === 0){
    return "#dfe1e6";
  }
  else if(value <= 1){
    return "#e4e6eb";
  }
  else if(value <= 2){
    return "#c8ccd7";
  }
  else if(value <= 3){
    return "#adb3c3";
  }
  else if(value <= 4){
    return "#919bb0";
  }
  else if(value <= 5){
    return "#74809a";
  }
  else if(value <= 6){
    return "#5b6889";
  }
  else if(value <= 7){
    return "#405074";
  }
  else if(value <= 8){
    return "#213664";
  }
  else{
    return "#505050";
  }
}

export default function RatingWheel(props){

  useEffect(() => {
    if(document.getElementById(props.id).children[0] !== undefined){
      document.getElementById(props.id).removeChild(document.getElementById(props.id).children[0]);
    }

    document.getElementById(props.id).append(new Knob({
      value: props.value,
      angleOffset: props.angleOffset ? props.angleOffset : 0,
      angleArc: props.angleOffset ? 360 - 2 * props.angleOffset : 360,
      min: 0,
      max: 8,
      width: props.width,
      thickness: props.thickness,
      lineCap: "round",
      bgColor: "#F0F0F0",
      fgColor: getColor(props.value),
      labelColor: '#000000',
      readOnly: !props.editable,
      displayInput: false,
      onChange: props.updateValue ? props.updateValue : (value) => console.log("error no function for update set, value " + value)
    }));
  }, [props.trigger]);

  return (<div id={props.id} className={props.classN}/>);
}

RatingWheel.propTypes = {
  width: PropTypes.number.isRequired,
  thickness: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  classN: PropTypes.string
};
