import * as React from 'react';
import {useContext, useState} from "react";

import './components.css';
import {
  close_t, dont_have_an_account, forgot_password, google_do_not_get_data,
  google_do_not_get_data_explaination, here_t, login_via_google,  read_more,
  sign_up, t
} from "./languages";
import {esc, Impressum, PasswordInput, showModal} from "./Utils";
import {GoogleLogin} from "@react-oauth/google";
import {GoogleOAuthProvider} from '@react-oauth/google';
import {getCookieConsentValue} from "react-cookie-consent";
import {postDataToUrl} from "./API";
import {ESMContext} from "../App";

export default function SignIn({setToken, setIsRegistered}){
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(0);
  const [readMore, setReadMore] = useState(0);
  const setError = useContext(ESMContext)

  function signIn(data){
    setError(null)
    console.log("asdf")
    setToken(data["token"]);
    if(getCookieConsentValue("acceptCookies") === "true"){
      localStorage.setItem('token', data["token"]);
      localStorage.setItem('userId', data["id"]);
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    let data = new FormData();
    data.append("email", esc(email));
    data.append("password", esc(password));
    postDataToUrl(null, 'login', data, signIn, setError);
  };

  function signInViaGoogle(credentialResponse){
    postDataToUrl(null, 'login/google?token=' + credentialResponse.credential, null, signIn);
  }

  function forgotPassword(){
    if(email.length < 6){
      setError("email to short")
      return;
    }
    let data = new FormData();
    data.append("email", esc(email));

    postDataToUrl(null, 'login/forgotPassword', data, handleForgotPW, setError);

    function handleForgotPW(){
      setError("password reset email sent")
    }

  }

  return (
    <form className="input_container" onSubmit={handleSubmit}>
      <input className="input" placeholder="E-Mail" onChange={e => setEmail(e.target.value)} autoComplete="email"/>
      <br/>

      <PasswordInput showPassword={showPassword} setPassword={setPassword} setShowPassword={setShowPassword}
                     autoComplete={"current-password"}/>

      <p className="errorShown alert" id={"errorFieldSignIn"}/>
      <p className="errorShown alert" id={"notificationFieldSignIn"} style={{color: "green"}}/>
      <br/>

      <button className="buttonLogin" onClick={e => handleSubmit(e)}>LOGIN</button>
      <br/>
      <br/>

      {getCookieConsentValue("acceptCookies") === "true" ?
        <GoogleOAuthProvider clientId="678762688388-vf7p7l5e019ipe7pcioej4je8kb50hv9">
          <GoogleLogin
            onSuccess={credentialResponse => {
              signInViaGoogle(credentialResponse);
            }}
            onError={() => {
              console.log('Login Failed');
            }}/>
        </GoogleOAuthProvider> :
        <div style={{color: "lightpink", border: "solid", borderWidth: 2}}>
          {t(login_via_google)}
        </div>}

      <p style={{fontSize: 10, opacity: 0.6}}>
        {t(google_do_not_get_data)} <span className="link" onClick={() => setReadMore(1)}> {t(read_more)}</span>
      </p>
      {readMore ? showModal(setReadMore, null, t(google_do_not_get_data_explaination), t(close_t), null) : null}

      <p>
        {t(dont_have_an_account)}
        <br/>{t(sign_up)}
        <span className="link" onClick={() => setIsRegistered(0)}> {t(here_t)}</span>.
      </p>

      <span className="link" onClick={forgotPassword}> {t(forgot_password)}</span>
      <br/>

      <Impressum/>
    </form>
  );
}

