import * as React from 'react';
import {useState} from "react";
import PropTypes from 'prop-types';

import './components.css';
import logo from "../assets/logo.png";
import SignIn from "./Signin";
import Signup from "./Signup";
import {getCookieConsent} from "./Utils";

export default function Login({setToken}){

  const [isRegistered, setIsRegistered] = useState(1);

  return (
    <div className="login">
      <img id="logo" src={logo} alt={"localisa logo"}/>
      <div className="sign">
        {isRegistered?
          <SignIn setToken={setToken} setIsRegistered={setIsRegistered}/>:
          <Signup setToken={setToken} setIsRegistered={setIsRegistered}/>
        }
      </div>
      {getCookieConsent(() => {
        window.location.reload();
      })}
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
