import * as React from 'react';
import {useContext, useEffect, useState} from "react";

import './components.css';
import {
  confirm_password_t,
  first_name, here_t,
  last_name,
  sign_in, t,
  you_have_already_an_account,
} from "./languages";
import {esc, Impressum, PasswordInput} from "./Utils";
import {getCookieConsentValue} from "react-cookie-consent";
import {postDataToUrl} from "./API";
import {ESMContext} from "../App";

export default function SignUp({setToken, setIsRegistered}){
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [showPassword, setShowPassword] = useState(0);
  const setError = useContext(ESMContext)

  useEffect(()=>setError(null), [password, email, conPassword, firstName, lastName, setError])

  function handleSignUp(data){

    if(getCookieConsentValue("acceptCookies") === "true"){
       localStorage.setItem('token', data["token"]);
       localStorage.setItem('userId', data["id"]);
    }
    setToken(data["token"]);

  }

  function handleSubmit(e){
    e.preventDefault();
    if(email.length < 5 || firstName.length < 2 || lastName.length < 2 || password.length < 4){
      setError("invalid input");
      return;
    }
    if(password !== conPassword){
      setError("passwords do not match");
      return;
    }
    let data = new FormData();
    data.append("email", esc(email));
    data.append("password", esc(password));
    data.append("firstName", esc(firstName));
    data.append("lastName", esc(lastName));

    postDataToUrl(null, 'signup', data, handleSignUp, setError);
  }

  return (
    <form className="input_container" onSubmit={handleSubmit}>
      <input className="input" placeholder={t(first_name)} onChange={e => setFirstName(e.target.value)}
             autoComplete="given-name"/>
      <br/>

      <input className="input" placeholder={t(last_name)} onChange={e => setLastName(e.target.value)}
             autoComplete="family-name"/>
      <br/>

      <input className="input" id="mail" placeholder="E-Mail" onChange={e => setEmail(e.target.value)}
             autoComplete="email"/>
      <p className="errorShown alert" id={"errorFieldSignUp"}/>
      <br/>

      <PasswordInput showPassword={showPassword} setPassword={setPassword} setShowPassword={setShowPassword}
                     autoComplete={"new-password"}/>
      <PasswordInput showPassword={showPassword} setPassword={setConPassword} autoComplete={"new-password"}
                     placeholder={t(confirm_password_t)}/>
      <p className="errorShown alert" id={"errorFieldSignUp1"}/>
      <br/>

      <button className="buttonLogin" onClick={e => handleSubmit(e)}>SIGN UP</button>
      <br/>
      <br/>

      <p>{t(you_have_already_an_account)}
        <br/>{t(sign_in)}
        <span className="link" onClick={() => setIsRegistered(1)}> {t(here_t)}</span>.
      </p>

      <Impressum/>
    </form>
  );
}
