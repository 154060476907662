import {useContext, useEffect, useState} from "react";
import * as loadImage from 'blueimp-load-image';

import defaultPic from "../assets/defaultOld.jpeg";
import removeIcon from "../assets/kreuz.png";
import {choose_img, t} from "./languages";
import {ESMContext, LocalisaContext} from "../App";
import {uploadImage} from "./API";
import {resizeImages} from "./Utils";

function addProgressBar(key){
  let progressBarBg = document.createElement('div')
  progressBarBg.className="picUploadProgress"
  progressBarBg.id="progress" + key
  let progressBar = document.createElement('div')
  progressBar.className="picUploadProgressBar"
  progressBar.id="progressBar" + key
  progressBarBg.appendChild(progressBar)
  document.getElementById("imgWrapper:" + key)?.lastChild.appendChild(progressBarBg);
}

function transformGivenImages(givenImages){
  let images_tmp = {};

  givenImages?.map((image) => {
    let name =
      image.includes("uploads/default") || image.includes("uploads/friendsIcon")?
        image.substring(image.lastIndexOf("uploads/")) :
        image.substring(image.lastIndexOf("/") + 1)
    images_tmp[name] = {url: image};
  });

  return images_tmp;
}

export default function UploadImages({setGivenImages, givenImages, single, setGPSInfo}){

  const [images, setImages] = useState([]);
  const [imageURLS, setImageURLS] = useState(transformGivenImages(givenImages));
  const [imagesUploadProgress, setImagesUploadProgress] = useState({});
  const token = useContext(LocalisaContext);
  const setError = useContext(ESMContext)

  function updateImages (name, progress){
    let tmp = {...imagesUploadProgress}
    tmp[name]? tmp[name].progress = progress: tmp[name]={progress:progress}
    setImagesUploadProgress(tmp)
  }
  useEffect(()=>{
    setGivenImages(Object.keys(imageURLS))
  },[imageURLS])

  useEffect(() => {
    let images_tmp = {...imageURLS}

    //create image url for preview and upload if not yet done
    images.forEach(image => {
      if(!images_tmp[image.name]?.url){
        images_tmp[image.name]={url:URL.createObjectURL(image), image: image}
        uploadImage(token, image,updateImages, (data)=> {
          setGivenImages(old=>old.map((img)=>img===image.name?data.info:img))
        },setError)
      }
      else{
        console.log("already uploaded:"+image.name)
      }
    });

    if(Object.keys(images_tmp).length > 1 && images_tmp["default.jpeg"])
      delete images_tmp["default.jpeg"]

    setImageURLS(images_tmp)
  }, [images]);

  useEffect(() => {
    if(Object.entries(imagesUploadProgress).length){
      Object.entries(imagesUploadProgress).map(([key, value], i) => {
        let progressBar = document.getElementById("progressBar" + key);
        if(progressBar){
          progressBar.style.width = 100 * value.progress + "%";
        }else if(value.progress < 1){
          addProgressBar(key)
          progressBar = document.getElementById("progressBar" + key);
          if(progressBar)
            progressBar.style.width = 100 * value.progress + "%";
        }
        if(value.progress === 1){
          let progress = document.getElementById("progress" + key);
            progress?.remove()
        }
        i++;
      });
    }
  },[imagesUploadProgress]);

  function onImageChange(e){
    console.log(e)
    for(let i = 0; i < e.target.files.length; i++){
      let pic = e.target.files[i];
      resizeImages(pic, setImages, single)
      //setImages(old => [...old, pic]);
      loadImage.parseMetaData(pic, (data) => {
        if(data.exif){
          const exifData = data.exif.getAll();
          if(exifData.GPSInfo){
            setGPSInfo(exifData.GPSInfo);
          }
        }
      });
    }

  }

  function removeImage(e){
    let imageToRemove = e.target.alt.toString()

    setImages(images.filter((image, index) => {
      return image.name !== imageToRemove;
    }));


    let images_tmp = {...imageURLS}
    Object.entries(images_tmp).map(([key])=> {
      if(imageToRemove === key)
        delete images_tmp[key]
    })
    setImageURLS(images_tmp)
  }

  return (
    <div>
      <div>{Object.entries(imageURLS).length < 1 ?
        <img className="picInputPreview" src={defaultPic} alt={"preview"}/> :
        Object.entries(imageURLS).map(([key, value], i) => <div id={"imgWrapper:" + key}>
            <div className="wrapperPicInputPreview">
              <img className="picInputPreview" src={value.url} id={"img:" + i} alt={"preview"}/>
              <img className="picInputPreviewRemove" alt={key} onClick={removeImage} src={removeIcon}
                   id={"imgRemover:" + i}/>
            </div>
          </div>
        )}
      </div>

      {single ?
        <input className="picInput" type="file" accept="image/*" onChange={onImageChange} id={"picInput"} hidden/> :
        <input className="picInput" type="file" accept="image/*" onChange={onImageChange} id={"picInput"} hidden multiple/>
      }

      <button className="buttonSq" style={{marginTop: 0}} onClick={() => {
        document.getElementById("picInput").click();
      }}>
        {t(choose_img)}
      </button>

    </div>
  );
}
