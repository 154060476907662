import * as React from 'react';

import './components.css';

import philip from '../assets/PhilipP.png';
import lisa from '../assets/LisaF.png';
import {useContext, useEffect, useState} from "react";
import {
  abort_t,
  app_about,
  both_t, change_password,
  concept_design, confirm_password_t, cookie_setting_warning,
  default_shown_locations,
  default_shown_ratings, delete_t, delete_user, delete_user_m, dont_share,
  friend_t, friends_t, hide_t,
  idea_t,
  implemented_by,
  language_t,
  mine_t, new_password_t, old_password_t,
  overall_t,
  own_t, password_changed, password_could_not_be_changed, passwords_do_not_match, save_t,
  settings_t, share_location_std_opt, share_t, show_city_in_newsfeed, show_t,
  t,
  tanks_text,
  thanks
} from "./languages";
import {esc, PasswordInput, showModal, ToggleSwitch} from "./Utils";
import {getCookieConsentValue} from "react-cookie-consent";
import {fetchLocalisa, postDataToUrl} from "./API";
import {LoadingContext, LocalisaContext} from "../App";

function PasswordChange({token, setLoading}){
  const [curPassword, setCurPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [changePw, setChangePw] = useState(false);
  const [error, setError] = useState(0);

  function changePassword(){
    if(newPassword !== conPassword){
      setError(1);
      return;
    }
    let data = new FormData();
    data.append("curPassword", esc(curPassword));
    data.append("newPassword", esc(newPassword));

    function handlePWChanged(){
      setError(3);
      setChangePw(false);
    }

    postDataToUrl(token, "user/changePassword", data, handlePWChanged, () => setError(2), setLoading);
  }

  return <>
    {changePw ?
      <div>
        <PasswordInput showPassword={showPassword} setPassword={setCurPassword}
                       setShowPassword={setShowPassword}
                       autoComplete={"current-password"} placeholder={t(old_password_t)}/>
        <PasswordInput setPassword={setNewPassword} autoComplete={"new-password"} placeholder={t(new_password_t)}/>
        <PasswordInput setPassword={setConPassword} autoComplete={"new-password"} placeholder={t(confirm_password_t)}/>
        {error === 1 ?
          <div className="alert">{t(passwords_do_not_match)}</div> :
          error === 2 ?
            <div className="alert">{t(password_could_not_be_changed)}</div> :
            null}
        <button className={"buttonSq"} onClick={changePassword}>{t(save_t)}</button>
      </div> :
      <button className={"buttonSq"} onClick={() => {
        setChangePw(true);
        setError(0);
      }}>
        {t(change_password)}
      </button>
    }
    {error === 3 ?
      <div className="alert" style={{color: "green"}}>{t(password_changed)}</div> :
      null}
  </>;
}

export default function Impressum(){
  const [categoryMap, setCategoryMap] = useState(localStorage.getItem("mapDefault") ? localStorage.getItem("mapDefault") : t(both_t));
  const categoriesMap = [own_t, friends_t, both_t];

  const [categoryRating, setCategoryRating] = useState(localStorage.getItem("locDefault") ? localStorage.getItem("locDefault") : t(mine_t));
  const categoriesRating = [mine_t, friend_t, overall_t];

  const [categoryLocNews, setCategoryLocNews] = useState(localStorage.getItem("locNewsDefault") ? localStorage.getItem("locNewsDefault") : t(show_t));
  const categoriesLocNews = [show_t, hide_t];

  const [categoryShareLoc, setCategoryShareLoc] = useState(localStorage.getItem("shareLocStdOpt") ? localStorage.getItem("shareLocStdOpt") : t(dont_share));
  const categoriesShareLoc = [share_t, dont_share];

  const [categoryLan, setCategoryLan] = useState(localStorage.getItem("language") ? localStorage.getItem("language") : "DE");
  const categoriesLan = ["DE", "EN"];

  const [deleteUser, setDeleteUser] = useState(0);
  const token = useContext(LocalisaContext);
  const setLoading = useContext(LoadingContext)

  useEffect(() => {
    const indexMap = categoriesMap.filter((cat) => {
      return t(cat) === (localStorage.getItem("mapDefault") ? localStorage.getItem("mapDefault") : t(both_t));
    });
    const indexLoc = categoriesRating.filter((cat) => {
      return t(cat) === (localStorage.getItem("locDefault") ? localStorage.getItem("locDefault") : t(mine_t));
    });
    const indexLocNews = categoriesLocNews.filter((cat) => {
      return t(cat) === (localStorage.getItem("locNewsDefault") ? localStorage.getItem("locNewsDefault") : t(show_t));
    });
    const indexShareLoc = categoriesShareLoc.filter((cat) => {
      return t(cat) === (localStorage.getItem("shareLocStdOpt") ? localStorage.getItem("shareLocStdOpt") : t(dont_share));
    });

    if(getCookieConsentValue("acceptCookies") === "true"){
      localStorage.setItem("language", categoryLan);
      localStorage.setItem("mapDefault", t(indexMap[0]));
      localStorage.setItem("locDefault", t(indexLoc[0]));
      localStorage.setItem("locNewsDefault", t(indexLocNews[0]));
      localStorage.setItem("shareLocStdOpt", t(indexShareLoc[0]));
    }
    setCategoryMap(t(indexMap[0]));
    setCategoryRating(t(indexLoc[0]));
    setCategoryLocNews(t(indexLocNews[0]));
    setCategoryShareLoc(t(indexShareLoc[0]));
  }, [categoryLan]);

  useEffect(() => {
    if(getCookieConsentValue("acceptCookies") === "true"){
      localStorage.setItem("mapDefault", categoryMap);
      localStorage.setItem("locDefault", categoryRating);
      localStorage.setItem("locNewsDefault", categoryLocNews);
      localStorage.setItem("shareLocStdOpt", categoryShareLoc);
    }
  }, [categoryRating, categoryMap, categoryLocNews, categoryShareLoc]);

  return (
    <div>
      <div className="impressum">
        <div>
          <h1>{t(app_about)}</h1>
          <p>{t(implemented_by)}</p>
          <div id="impressumPhotos">
            <img id="impressumPhoto" src={philip} alt={""}/>
            <img id="impressumPhoto" src={lisa} alt={""}/>
            <b id="impressumPhoto">Philip Pannagger</b>
            <b id="impressumPhoto">Lisa Fresser</b>
          </div>
          <hr/>
          <h1 className="text-align-left margin-left">{t(settings_t)}</h1>
          {
            getCookieConsentValue("acceptCookies") !== "true" ?
              <div className="alert">{t(cookie_setting_warning)}</div> :
              null
          }
          <div className="text-align-left margin-left">{t(language_t)}</div>
          <ToggleSwitch className="margin-left-" categories={categoriesLan} setCategory={setCategoryLan}
                        category={categoryLan}/>
          <div className="text-align-left margin-left">{t(default_shown_locations)}</div>
          <ToggleSwitch className="margin-left-" categories={categoriesMap.map((a) => t(a))}
                        setCategory={setCategoryMap} category={categoryMap}/>
          <div className="text-align-left margin-left">{t(default_shown_ratings)}</div>
          <ToggleSwitch className="margin-left-" categories={categoriesRating.map((a) => t(a))}
                        setCategory={setCategoryRating} category={categoryRating}/>
          <div className="text-align-left margin-left">{t(show_city_in_newsfeed)}</div>
          <ToggleSwitch className="margin-left-" categories={categoriesLocNews.map((a) => t(a))}
                        setCategory={setCategoryLocNews} category={categoryLocNews}/>
          <div className="text-align-left margin-left">{t(share_location_std_opt)}</div>
          <ToggleSwitch className="margin-left-" categories={categoriesShareLoc.map((a) => t(a))}
                        setCategory={setCategoryShareLoc} category={categoryShareLoc}/>
          <PasswordChange token={token} setLoading={setLoading}/>

          <hr/>
          <br/>
          <br/>
          <p>
            Head: <b>Philip Pannagger</b>
            <br/>
            {t(idea_t)}:
            <b> Erhard Fresser</b>
            <br/>{t(concept_design)}:
            <b> Lisa Fresser</b>
            <br/>
          </p>
          <br/>
          <i> {t(thanks)} <br/>
            {t(tanks_text)}
            <br/> <span>&#9786;</span></i>

          <br/>
          <br/>
          {t(delete_user)}
          <br/>
          <button className="buttonSq bgRed" onClick={() => setDeleteUser(1)}>{t(delete_t)}</button>
          {deleteUser ? showModal(setDeleteUser, () => {
            fetchLocalisa(token, "users", () => {
              localStorage.clear();
              window.location.reload();
            }, null, null, setLoading, "DELETE");
          }, t(delete_user_m), t(abort_t), t(delete_t)) : null}
          <br/>
          &#8226;
          <br/>
          <a href="https://localisa.app/datenschutz"
             target="_blank" rel="noreferrer"> {"Datenschutz"}</a>
          {" "}
          <a href="https://localisa.app/impressum"
             target="_blank" rel="noreferrer"> {"Impressum"}</a>
          <br/>
          <br/>© localisa v1.0.0, 2022
          <br/>
          <br/>
          <br/>
          <br/>
        </div>
      </div>
    </div>
  );

}

